import { useMixpanel } from '../context'

export function useTrackDragCourse() {
    const { trackEvent } = useMixpanel()

    function trackDragCourse() {
        trackEvent('Drag Course', {})
    }

    return trackDragCourse
}
