import InboxIcon from '@mui/icons-material/Inbox'
import { Box, Button, List, Menu, Paper, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useTrackOpenSavedScedule } from '../../services/analytics/hook'
import {
    localStorageScheduleDeleteOneFn,
    localStorageScheduleGetAllFn,
} from '../../services/localStorage/localStorageSchedule'
import { SavedSchedule } from '../../types/SavedScheduleDD'
import { useLoadSchedule } from './hooks'
import { Delete } from '@mui/icons-material'
import { useForceUpdate } from '../../utils/hooks/useForceUpdate'
import { enqueueSnackbar } from 'notistack'
import { InfoMessageScheduleDeleted } from '../../components/snackbar'
import { WindowEventNotifyNewSavedSchedules } from '../../services/windowEvents/windowEventSchedule'

interface LoadScheduleMenuProps {
    menuOpen: boolean
    menuId: string
    ariaLabelledBy: string
    menuAnchorEl: HTMLElement | null
    handleMenuClose: () => void
}

export const LoadScheduleMenu = ({
    menuOpen,
    menuId,
    ariaLabelledBy,
    menuAnchorEl,
    handleMenuClose,
}: LoadScheduleMenuProps) => {
    const trackOpenSavedSchedule = useTrackOpenSavedScedule()
    const { loadSchedule } = useLoadSchedule()
    const forceUpdate = useForceUpdate()

    let allSavedSchedules = localStorageScheduleGetAllFn()

    function formatDate(date: string) {
        return new Date(date).toISOString().split('T')[0]
    }

    const handleLoadSavedSchedule = (savedSchedule: SavedSchedule) => {
        trackOpenSavedSchedule()
        loadSchedule(savedSchedule.id)
        handleMenuClose()
    }

    const handleDeleteSavedSchedule = (savedSchedule: SavedSchedule) => {
        localStorageScheduleDeleteOneFn(savedSchedule.id)
        enqueueSnackbar(InfoMessageScheduleDeleted(savedSchedule.title), { variant: 'info' })
        allSavedSchedules = localStorageScheduleGetAllFn()
        WindowEventNotifyNewSavedSchedules() // Notify all tabs that a schedule has been deleted
        forceUpdate()
    }

    return (
        <Menu
            id={menuId}
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': ariaLabelledBy,
            }}
        >
            <Paper elevation={0}>
                {allSavedSchedules.length === 0 ? (
                    <Typography fontSize={9} color={'grey'} style={{ width: 'max-content', padding: 5 }}>
                        <InboxIcon sx={{ marginRight: 1 }} />
                        No Saved Schedules
                    </Typography>
                ) : (
                    <List sx={{ overflow: 'auto', maxHeight: 150 }}>
                        {allSavedSchedules.map((savedSchedule: SavedSchedule, index: number) => (
                            <motion.div
                                key={index}
                                initial={{ y: -50, opacity: 0, filter: 'blur(2px)' }}
                                animate={{ y: 0, opacity: 1, filter: 'blur(0px)' }}
                                transition={{ duration: 0.35, delay: index * 0.25 }}
                            >
                                <Stack
                                    key={crypto.randomUUID()}
                                    direction={'row'}
                                    style={{ display: 'flex', minWidth: 220 }}
                                >
                                    <Button
                                        onClick={() => handleLoadSavedSchedule(savedSchedule)}
                                        fullWidth
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <Box
                                            className="schedule-thumbnail-img"
                                            component="img"
                                            src={savedSchedule.thumbnail}
                                        />
                                        <Stack>
                                            <Typography fontSize={11}>{savedSchedule.title}</Typography>
                                            <Typography fontSize={11}>{formatDate(savedSchedule.date)}</Typography>
                                        </Stack>
                                    </Button>
                                    <Button size={'small'} onClick={() => handleDeleteSavedSchedule(savedSchedule)}>
                                        <Delete />
                                    </Button>
                                </Stack>
                            </motion.div>
                        ))}
                    </List>
                )}
            </Paper>
        </Menu>
    )
}
