import { useMixpanel } from '../context'

export function useTrackOpenSavedScedule() {
    const { trackEvent } = useMixpanel()
    function trackOpenSavedSchedule() {
        trackEvent('Open Saved Schedule', {})
    }

    return trackOpenSavedSchedule
}
