export const iconSizeAndColorProps = (colorsStyle: 'light' | 'dark' | 'disabled') => {
    // disabled
    let color = '#BDBDBD'
    if (colorsStyle === 'dark') color = '#1976D2'
    if (colorsStyle === 'light') color = 'white'

    return { color: color, size: 25 }
}
export const iconSizeAndColorPropsCustomButton = (colorsStyle: 'light' | 'dark') => {
    return { color: colorsStyle === 'light' ? 'white' : '#1976D2', size: 20 }
}
