import { Accordion, AccordionDetails, AccordionSummary, Tooltip, Typography } from '@mui/material'
import React, { memo, useContext, useEffect, useState } from 'react'
import { SectionsContext } from '../../context/SectionsContext'
import { ExpandMore, Clear, Check } from '@mui/icons-material'
import { convertTimeslotsToTime } from '../../utils/time'
import { Section } from '../../types/SectionDD'
import { getSection } from '../../services/api/APIWebCrawler'
import { AccessTime, PersonOutline, BusinessOutlined, DoorFrontOutlined, FmdGoodOutlined } from '@mui/icons-material'
import { tCourseInfo } from '../../types/CourseInfoDD'
import ScienceIcon from '@mui/icons-material/Science'
import CoPresentIcon from '@mui/icons-material/CoPresent'
import ClassIcon from '@mui/icons-material/Class'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import { Campus } from '../../types/CampusDD'

interface IProps {
    classType: string
    course: tCourseInfo
    isFirstSectionRendered: boolean
    campusInfo: Campus
}

const ClassInfo = memo(({ classType, course, isFirstSectionRendered, campusInfo }: IProps) => {
    const [accordionExpanded, setAccordionExpanded] = useState<boolean>(false)
    const { currentSections } = useContext(SectionsContext)
    const [currentSection, setCurrentSection] = useState<any>(null)

    const getCourseIcon = (classType: string) => {
        if (classType === 'Lecture') return <MenuBookIcon />
        if (classType === 'Laboratory') return <ScienceIcon />
        if (classType === 'Tutorial') return <CoPresentIcon />
        return <ClassIcon />
    }

    // API Call - Get Specific Course Section Info
    const getSectionInfo = async (courseSection: any) => {
        let schedulePrettier = convertTimeslotsToTime(courseSection.schedule)
        // Scrap SSC Info
        const scrapedSectionInfo = await getSection(
            courseSection.subject,
            courseSection.course,
            course.courseSession,
            courseSection.section,
            campusInfo.name
        )
        let newCourseSection = { schedulePrettier: schedulePrettier, ...courseSection, ...scrapedSectionInfo }
        setCurrentSection(newCourseSection)
    }

    // Gets when specific course section for this specific component - eg CPSC 110 LAB
    const getSpecificSection = () => {
        const currentSectionAray: Section[] = currentSections.filter(
            (currentSection: Section) =>
                currentSection.activity === classType &&
                currentSection.subject === course.department &&
                currentSection.course == course.courseNumber
        )
        return currentSectionAray[0]
    }

    // Gets the specific couse section from currentSections - eg CPSC 110 LAB
    const currentSpecificSection = getSpecificSection()

    useEffect(() => {
        if (!currentSpecificSection) {
            setCurrentSection(null)
            setAccordionExpanded(false)
            return
        }

        // Force First Section of First course to open on clicking "Generate Schedule"
        if (isFirstSectionRendered && currentSection == null) {
            setAccordionExpanded(true)
        }

        // Prevents API Call if Accordion is not expanded
        // 1. Fetch if => Accordion Expanded && Have Never Fetched
        // 2. Fetch if => Accordion Expanded && Dragged and Dropped to New Section
        if (
            (accordionExpanded && currentSection === null) ||
            (accordionExpanded && currentSpecificSection.name !== currentSection.name)
        ) {
            getSectionInfo(currentSpecificSection)
        }
        // Gets the specific couse section from currentSections - eg CPSC 110 LAB
    }, [currentSpecificSection, accordionExpanded])

    if (!course.courseGenerated) return null

    return (
        <>
            <Accordion
                onChange={() => setAccordionExpanded((isExpanded: boolean) => !isExpanded)}
                expanded={accordionExpanded}
                disableGutters
                disabled={!currentSpecificSection?.selectedForScheduleSolver}
            >
                <AccordionSummary
                    expandIcon={currentSpecificSection?.selectedForScheduleSolver && <ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className="flex-space-between">
                        <RowIconText
                            icon={getCourseIcon(classType)}
                            info={<b style={{ fontSize: '0.8rem', paddingLeft: 10 }}>{classType}</b>}
                        />
                    </div>
                </AccordionSummary>

                <AccordionDetails>
                    {currentSection?.name && (
                        <div className="flex-space-between">
                            <Tooltip title="More Info">
                                <Typography variant="body1">
                                    <a
                                        style={{ textDecoration: 'underline' }}
                                        target="_blank"
                                        rel="noreferrer"
                                        href={currentSection.url}
                                    >
                                        {currentSection?.name}
                                    </a>
                                </Typography>
                            </Tooltip>
                            <RowTextIcon
                                key={'mode'}
                                icon={<FmdGoodOutlined sx={{ fontSize: 20 }} />}
                                info={currentSection?.mode}
                            />
                        </div>
                    )}
                    {currentSection?.schedulePrettier.length > 0 &&
                        currentSection.schedulePrettier.map((x: any) => (
                            <RowIconText
                                key={x.start_time + x.end_time + x.days}
                                icon={<AccessTime sx={{ fontSize: 18 }} />}
                                info={`${x.start_time} - ${x.end_time} | ${x.days}`}
                            />
                        ))}
                    {/* {currentSection?.building && <RowIconText key={'building'} icon={<BusinessOutlined sx={{ fontSize: 18 }} />} info={currentSection?.building} />} */}
                    {/* {currentSection?.room && <RowIconText key={'room'} icon={<DoorFrontOutlined sx={{ fontSize: 18 }} />} info={`Room: ${currentSection?.room}`} />} */}
                    {/* {currentSection?.instructor && (
                        <RowIconText
                            key={'instructor'}
                            icon={<PersonOutline sx={{ fontSize: 20 }} />}
                            info={
                                currentSection?.instructor === 'TBA' ? (
                                    'Instructor: TBA'
                                ) : (
                                    <a target="_blank" href={currentSection?.instructorUrl}>
                                        {currentSection?.instructor}
                                    </a>
                                )
                            }
                        />
                    )} */}
                    {currentSection?.currentlyRegistered.length > 0 && (
                        <SeatsRemainingRow
                            key={'currentlyRegistered'}
                            isLeftPadding={false}
                            heading="Available Seats"
                            hasSeatsAvailable={parseInt(currentSection?.totalSeatsRemaining) > 0}
                            seats={`${currentSection.totalSeatsRemaining} / ${
                                parseInt(currentSection.totalSeatsRemaining) +
                                parseInt(currentSection.currentlyRegistered)
                            }`}
                        />
                    )}
                    {currentSection?.generalSeatsRemainings.length > 0 && ( // prettier-ignore
                        <SeatsRemainingRow
                            key={'GeneralSeats'}
                            isLeftPadding={true}
                            heading="General Seats"
                            hasSeatsAvailable={parseInt(currentSection?.generalSeatsRemainings) > 0}
                            seats={currentSection.generalSeatsRemainings}
                        />
                    )}
                    {currentSection?.restrictedSeatsRemaining.length > 0 && (
                        <SeatsRemainingRow
                            key={'restrictedSeatsRemaining'}
                            isLeftPadding={true}
                            heading="Restricted Seats"
                            hasSeatsAvailable={parseInt(currentSection?.restrictedSeatsRemaining) > 0}
                            seats={currentSection.restrictedSeatsRemaining}
                        />
                    )}
                </AccordionDetails>
            </Accordion>
        </>
    )
})

export default ClassInfo

interface InfoProps {
    icon: React.ReactNode
    info: string | React.ReactNode
    width?: number | string
}

const RowIconText = ({ icon, info }: InfoProps) => {
    return (
        <div className="flex-row-align-center">
            <Typography component={'span'} variant="body1" className="flex-row-align-center">
                <span>{icon}</span>
                <span style={{ paddingLeft: 5 }}>{info}</span>
            </Typography>
        </div>
    )
}

const RowTextIcon = ({ icon, info }: InfoProps) => {
    return (
        <div className="flex-row-align-center">
            <Typography component={'span'} variant="body1" className="flex-row-align-center">
                {info} {icon}
            </Typography>
        </div>
    )
}

interface SeatsRemainingRowProps {
    isLeftPadding: boolean
    heading: string
    hasSeatsAvailable: boolean
    seats: string
}

const SeatsRemainingRow = ({ isLeftPadding, hasSeatsAvailable, heading, seats }: SeatsRemainingRowProps) => {
    return (
        <div className="flex-space-between" style={{ width: '100%', paddingLeft: isLeftPadding ? 15 : '' }}>
            <div className="flex-row-align-center">
                {hasSeatsAvailable ? (
                    <Check sx={{ fontSize: 20, color: 'green' }} />
                ) : (
                    <Clear sx={{ fontSize: 20, color: 'red' }} />
                )}
                <Typography component={'span'} variant="body1">
                    {heading}
                </Typography>
            </div>
            <Typography variant="body1">
                <b>{seats}</b>
            </Typography>
        </div>
    )
}
