import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { getSection } from '../../../services/api/APIWebCrawler'
import { DetailedSection, Section } from '../../../types/SectionDD'
import { useCoursesInfo } from '../../../context/CoursesInfoContext'
import { tCourseInfo } from '../../../types/CourseInfoDD'
import {
    TravelInfo,
    TravelInfoPromptState,
    TravelInfoUpdatersContextType,
    TravelInfoValuesContextType,
} from '../../../types/TravelInfoDD'
import { useGetTravelInfo } from '../hook/index'
import { useCampusInfo } from '../../../context/CoursesInfoContext'

interface TravelInfoProviderProps {
    children: ReactNode
}

const TravelInfoUpdatersContext = createContext<TravelInfoUpdatersContextType>({
    reset: () => {},
    activate: () => {},
    setSectionDestination: () => {},
    setSectionOrigin: () => {},
})

const TravelInfoValuesContext = createContext<TravelInfoValuesContextType>({
    isActive: true,
    sectionDestination: null,
    detailedSectionDestination: null,
    sectionOrigin: null,
    detailedSectionOrigin: null,
    travelInfoPromptState: TravelInfoPromptState.Empty,
    travelInfo: null,
})

export const useTravelInfoUpdaters = () => useContext(TravelInfoUpdatersContext)
export const useTravelInfoValues = () => useContext(TravelInfoValuesContext)

function TravelInfoProvider({ children }: TravelInfoProviderProps) {
    const [isActive, setIsActive] = useState(false)

    const [sectionOrigin, setSectionOrigin] = useState<null | Section>(null)
    const detailedSectionOrigin = useDetailedSection(sectionOrigin)

    const [sectionDestination, setSectionDestination] = useState<null | Section>(null)
    const detailedSectionDestination = useDetailedSection(sectionDestination)

    const travelInfoPromptState: TravelInfoPromptState = useTravelInfoPromptState(sectionOrigin, sectionDestination)

    const [travelInfo, setTravelInfo] = useState<TravelInfo | null>(null)

    const getTravelInfo = useGetTravelInfo()

    function reset() {
        setIsActive(false)
        setSectionOrigin(null)
        setSectionDestination(null)
    }

    function activate() {
        return setIsActive(true)
    }

    useEffect(() => {
        async function setAppropriateTravelInfo() {
            if (detailedSectionOrigin && detailedSectionDestination && !travelInfo) {
                let newTravelInfo: TravelInfo
                try {
                    newTravelInfo = await getTravelInfo(
                        detailedSectionOrigin.building,
                        detailedSectionDestination.building
                    )
                } catch {
                    newTravelInfo = {
                        buildingDestination: detailedSectionDestination.building,
                        buildingOrigin: detailedSectionOrigin.building,
                        distanceInMeter: 0,
                        timeInSecond: 0,
                    }
                }

                return setTravelInfo(newTravelInfo)
            }
        }

        if (!sectionOrigin || !sectionDestination) {
            setTravelInfo(null)
        } else {
            setAppropriateTravelInfo()
        }
    }, [
        detailedSectionOrigin,
        detailedSectionDestination,
        sectionOrigin,
        sectionDestination,
        getTravelInfo,
        travelInfo,
    ])

    return (
        <TravelInfoValuesContext.Provider
            value={{
                isActive,
                detailedSectionDestination,
                detailedSectionOrigin,
                travelInfoPromptState,
                travelInfo,
                sectionDestination,
                sectionOrigin,
            }}
        >
            <TravelInfoUpdatersContext.Provider value={{ reset, activate, setSectionDestination, setSectionOrigin }}>
                {children}
            </TravelInfoUpdatersContext.Provider>
        </TravelInfoValuesContext.Provider>
    )
}

function useTravelInfoPromptState(
    sectionOrigin: null | Section,
    sectionDestination: null | Section
): TravelInfoPromptState {
    if (sectionOrigin && sectionDestination) {
        return TravelInfoPromptState.Full
    }

    if (sectionOrigin) {
        return TravelInfoPromptState.NeedToFillDestination
    }

    if (sectionDestination) {
        return TravelInfoPromptState.NeedToFillOrigin
    }

    return TravelInfoPromptState.Empty
}

function useDetailedSection(sectionOrNull: Section | null): DetailedSection | null {
    const [detailedSection, setDetailedSection] = useState<DetailedSection | null>(null)
    const { courses } = useCoursesInfo()
    const campusInfo = useCampusInfo()

    const findCourse = useCallback(
        (section: Section) => {
            return courses.find(
                (course) => course.department === section.subject && section.course === course.courseNumber
            )
        },
        [courses]
    )

    useEffect(() => {
        async function getDetailedSection() {
            let section: Section = sectionOrNull as Section
            let courseInfoOrNull = findCourse(section)

            if (!courseInfoOrNull) {
                return setDetailedSection(null)
            }

            let course: tCourseInfo = courseInfoOrNull

            let fetchedDetailedSection: DetailedSection
            try {
                let fetchedDetailedSectionExtraPart = await getSection(
                    section.subject,
                    section.course,
                    course.courseSession,
                    section.section,
                    campusInfo.name
                )
                fetchedDetailedSection = { ...section, ...fetchedDetailedSectionExtraPart }
            } catch {
                return setDetailedSection(null)
            }

            return setDetailedSection(fetchedDetailedSection)
        }

        if (!sectionOrNull) {
            setDetailedSection(null)
        } else {
            getDetailedSection()
        }
    }, [sectionOrNull, findCourse])

    return detailedSection
}

export default TravelInfoProvider
