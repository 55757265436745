import { Typography } from '@mui/material'
import { useContext } from 'react'
import { UndoRedoContext } from '../../context/UndoRedoContext'
import { useTrackClickUndoRedo } from '../../services/analytics/hook'
import { ButtonWithTooltip } from '../../components/button'
import { LuRedo2 } from 'react-icons/lu'
import { iconSizeAndColorProps } from '../../theme/constants'

export const RedoButton = ({ isMobile }: { isMobile?: boolean }) => {
    const { redo, canRedo } = useContext(UndoRedoContext)
    const trackClickUndoRedo = useTrackClickUndoRedo()
    //
    const handleRedo = () => {
        if (!canRedo()) return
        trackClickUndoRedo()
        redo()
    }

    const disabled = !canRedo()

    return (
        <ButtonWithTooltip disabled={!canRedo()} onClick={() => handleRedo()} tooltipTitle="Redo" width="20px">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <LuRedo2 {...iconSizeAndColorProps(disabled ? 'disabled' : 'dark')} />
                {isMobile && (
                    <Typography fontSize={9} variant={'caption'}>
                        Redo
                    </Typography>
                )}
            </div>
        </ButtonWithTooltip>
    )
}
