import { MenuItem, TextField } from '@mui/material'
import { withScheduleRecommendationLogic, ScheduleRecommendationDropDownProps } from './withScheduleRecommendationLogic'

// PAY ATTENTION TO -> with ScheduleRecommendationLogic -> all logic is there
export const ScheduleRecommendationMobileDropDown = withScheduleRecommendationLogic(
    ({ scheduleOptions, handleSwitchRecommendation, selectedRecommended }: ScheduleRecommendationDropDownProps) => {
        return (
            <TextField
                id="term-choice-field"
                value={selectedRecommended}
                select
                onChange={handleSwitchRecommendation}
                size="small"
                sx={{ [`& fieldset`]: { borderRadius: '20px' } }}
            >
                {scheduleOptions.map((scheduleOption, key) => {
                    return (
                        <MenuItem key={key} value={scheduleOption.value} disabled={scheduleOption.disable}>
                            {scheduleOption.name}
                            {scheduleOption.disable && ' (None)'}
                        </MenuItem>
                    )
                })}
            </TextField>
        )
    }
)
