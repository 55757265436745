const DragAndDropTimeslot = (props: any) => {
    const { item, style } = props
    const newStyle = {
        color: item.color,
        backgroundColor: item.backgroundColor,
        height: item.timeSlot.end_time - item.timeSlot.start_time - 4,
        ...style,
    }

    return (
        <div className="solid-cal-slot cal-slot" style={newStyle}>
            <div className="cal-slot-details">
                <div>{item.section.subject}</div>
                <div>{item.section.course}</div>
            </div>
            <div>{item.section.section}</div>
        </div>
    )
}

export default DragAndDropTimeslot
