import React, { ComponentType, useContext, useMemo } from 'react'
import { SectionsContext } from '../../context/SectionsContext'
import { Recommended } from '../../types/RecommendDD'
import { countFreeDays } from '../coursesearch/hooks/useGenerateSchedule/recommend'
import { useTrackClickChooseScheduleOption } from '../../services/analytics/hook'
import { SelectChangeEvent } from '@mui/material'
import { HigherOrderComponent } from '../../types/ComponentUtilsDD'

export type tScheduleOption = {
    name: string
    value: Recommended
    disable: boolean
}

export interface ScheduleRecommendationDropDownProps {
    scheduleOptions: tScheduleOption[]
    handleSwitchRecommendation: (e: React.ChangeEvent<HTMLInputElement>) => void
    selectedRecommended: Recommended
    isDisabled?: boolean
}

export interface withScheduleRecommendationLogicProps extends Pick<ScheduleRecommendationDropDownProps, 'isDisabled'> {}

// This is a higher order component
// It takes in a component and returns a new component with logic
export const withScheduleRecommendationLogic = (
    OriginalComponent: ComponentType<ScheduleRecommendationDropDownProps>
) => {
    return ({ isDisabled }: withScheduleRecommendationLogicProps) => {
        const { setSelectedRecommended, selectedRecommended, recommended } = useContext(SectionsContext)

        const trackClickChooseScheduleOption = useTrackClickChooseScheduleOption()

        const noFreeDays = useMemo(() => countFreeDays(recommended.freeDay) === 0, [recommended.freeDay])

        const handleSwitchRecommendation = (e: SelectChangeEvent<Recommended>) => {
            const recommendation = e.target.value as unknown as Recommended
            setSelectedRecommended(recommendation)
            trackClickChooseScheduleOption(scheduleOptions, recommendation)
        }

        const scheduleOptions: tScheduleOption[] = [
            { name: 'Short Breaks', value: Recommended.compact, disable: false },
            { name: 'Long Breaks', value: Recommended.scattered, disable: false },
            { name: 'Consistent Start Time', value: Recommended.consistent, disable: false },
            { name: 'Days off', value: Recommended.freeDays, disable: noFreeDays },
            { name: 'Start Late', value: Recommended.latestStart, disable: false },
            { name: 'End Early', value: Recommended.earliestEnd, disable: false },
        ]

        return (
            <OriginalComponent
                scheduleOptions={scheduleOptions}
                handleSwitchRecommendation={handleSwitchRecommendation}
                selectedRecommended={selectedRecommended}
                isDisabled={isDisabled}
            />
        )
    }
}
