import React, { useState } from 'react'
import { CalendarNavbarContainer, UndoRedoNavbarContainer, WalkIcon } from './CalendarNavbar.styles'
import { ButtonWithIcon } from '../../../components/button'
import { useTheme } from '@mui/material'
import { DisappearingItem } from '../../../components/mediaQueriesComponents'
import { ScheduleRecommendationDesktopDropDown } from '../../scheduleRecommendationDropDown'
import { RedoButton, UndoButton } from '../../undoRedo'
import { TravelInfoPromptDesktop } from '../../travelinfo/components/TravelInfoPrompt'
import { useTravelInfoPromptActiveStateManager } from '../../travelinfo/hook'
import { useCoursesInfoSetState } from '../../../context/CoursesInfoContext'

export const CalendarNavbar = () => {
    const { palette } = useTheme()

    const { onTravelInfoEnter, onTravelInfoExit } = useTravelInfoPromptActiveStateManager()
    const [travelInfoIsActive, toggle] = useFeatureState(onTravelInfoEnter, onTravelInfoExit)

    const { checkIfScheduleGenerated } = useCoursesInfoSetState()

    const isDisabled = !checkIfScheduleGenerated()

    return (
        <DisappearingItem widthToDisappear={'651px'}>
            <CalendarNavbarContainer>
                <UndoRedoNavbarContainer>
                    <UndoButton />
                    <RedoButton />
                </UndoRedoNavbarContainer>
                <ScheduleRecommendationDesktopDropDown isDisabled={isDisabled} />
                {/* {travelInfoIsActive ? (
                    <TravelInfoPromptDesktop onTravelInfoExit={toggle} />
                ) : (
                    <ButtonWithIcon
                        width={'146px'}
                        backgroundColor="white"
                        onClick={toggle}
                        borderColor={palette.primary.main}
                        isDisabled={isDisabled}
                    >
                        <WalkIcon style={{ color: isDisabled ? 'grey' : palette.primary.main }} />
                        <ButtonWithIcon.Text color={isDisabled ? 'grey' : palette.primary.main}>
                            Travel Estimator
                        </ButtonWithIcon.Text>
                    </ButtonWithIcon>
                )} */}
            </CalendarNavbarContainer>
        </DisappearingItem>
    )
}

function useFeatureState(onActive?: () => void, onInactive?: () => void): [boolean, () => void] {
    const [isActive, setIsActive] = useState(false)

    function toggle() {
        const newIsActive = !isActive

        if (newIsActive) {
            onActive && onActive()
        } else {
            onInactive && onInactive()
        }

        return setIsActive(newIsActive)
    }

    return [isActive, toggle]
}
