import { filterDuplicatedSchedules } from '../../../../utils/filter'
import { groupSections } from '../../../../utils/groupby'
import { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { useCoursesInfo, useCoursesInfoSetState } from '../../../../context/CoursesInfoContext'
import { SectionsContext } from '../../../../context/SectionsContext'
import { UndoRedoContext } from '../../../../context/UndoRedoContext'
import { getSectionsSelectedForScheduleSolver } from './getSectionsSelectedForScheduleSolver'
import { useSnackbar } from 'notistack'
import { ErrorMessageGenerateScheduleNoCourses, WarningMessageCourseOverlapping } from '../../../../components/snackbar'
import { instructionGenerateSchedule, useInstruction } from '../../../../context/InstructionContext'
import { recommend } from './recommend'
import { solveMultiThreaded } from './solveMultiThreaded'
import { Schedule } from '../../../../types/ScheduleDD'
import { solve } from './solve_newengine'

/** solve and generate schedule recommendation */
type GenerateScheduleProps = {
    setLoading?: Dispatch<SetStateAction<boolean>>
}

/** solve and generate schedule recommendation */
export const useGenerateSchedule = () => {
    const { setSections, setRecommended } = useContext(SectionsContext)
    const { clearUndoRedo } = useContext(UndoRedoContext)
    const { setCoursesAsGenerated } = useCoursesInfoSetState()
    const { courses } = useCoursesInfo()
    const { enqueueSnackbar } = useSnackbar()
    const { nextInstruction } = useInstruction()

    const generateSchedule = useCallback(
        (setLoading: Function) => {
            if (courses.length === 0)
                return enqueueSnackbar(ErrorMessageGenerateScheduleNoCourses, { variant: 'error' })
            setLoading(true)

            let sectionsSelected = getSectionsSelectedForScheduleSolver(courses)
            let sectionsNoDuplicate = filterDuplicatedSchedules(sectionsSelected)
            let sectionsGroup = groupSections(sectionsNoDuplicate)

            solveMultiThreaded(sectionsGroup, (sectionsSolved: Schedule[], hasConflict: boolean[]) => {
                const sectionsRecommended = recommend(sectionsSolved)
                setSections(sectionsGroup.flatMap((section) => section)) // Do we Need this?
                setRecommended(sectionsRecommended)
                if (hasConflict) {
                    // if true, this means solve algorithm encountered conflict,
                    // and was not able to produce a valid schedule.
                    // we still provide a schedule, eventhough it has conflict
                    enqueueSnackbar(WarningMessageCourseOverlapping, { variant: 'warning', autoHideDuration: 5000 })
                }
                clearUndoRedo()
                setCoursesAsGenerated()
                nextInstruction(instructionGenerateSchedule)
                setLoading(false)
            })
        },
        [courses, setRecommended, setSections, clearUndoRedo]
    )

    return generateSchedule
}
