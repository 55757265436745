import { useEffect, useRef, useState } from 'react'

const useInView = ({threshold}:{threshold:number}) => {
    const inViewRef = useRef<HTMLElement>(null)
    const [inView, setinView ] = useState(false)

    useEffect(() => {
        function handleScroll() {
            if (inViewRef.current) {
                const element = inViewRef.current.getBoundingClientRect()
                const viewportHeight = window.innerHeight
                if (element.bottom - (threshold * viewportHeight) > 0 && element.top + (threshold * viewportHeight) < viewportHeight) {
                    setinView(true)
                } else {
                    setinView(false)
                }
            } 
        }
        handleScroll()
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

  return {inViewRef, inView}
}

export default useInView