import { useContext } from 'react'
import { useCoursesInfoSetState } from '../../context/CoursesInfoContext'
import { SectionsContext } from '../../context/SectionsContext'

interface SessionStoreGetScheduleType {
    session: string
    term: string
}

export const useSessionStoreGetSchedule = () => {
    const { setRecommended, setSelectedRecommended, setSections, setCurrentSections } = useContext(SectionsContext)
    const { setCoursesInfo } = useCoursesInfoSetState()

    /**
     * Gets Schedule from Session Storage. If schedule found sets context to schedule.
     * @param session - new session
     * @param term    - new term
     * @returns boolean - true if schedule is found in session storage, false otherwise
     */
    const sessionStoreGetSchedule = ({ session, term }: SessionStoreGetScheduleType): boolean => {
        const data = sessionStoreGetScheduleFN({ session, term })

        // schedule not stored in session storage
        if (data === null) return false

        // schedule found in session storage
        setRecommended(data.recommended)
        setSelectedRecommended(data.selectedRecommended)
        setSections(data.sections)
        setCurrentSections(data.currentSections)
        setCoursesInfo(data.coursesInfo)
        return true
    }
    return { sessionStoreGetSchedule }
}

export const sessionStoreGetScheduleFN = ({ session, term }: SessionStoreGetScheduleType): any => {
    const dataJson = window.sessionStorage.getItem(session + term)
    if (dataJson === null) return null

    return JSON.parse(dataJson)
}
