import mixpanel from 'mixpanel-browser'
import { ReactNode, createContext, useContext, useEffect } from 'react'
import { EventMap, MixpanelContextType } from '../../../types/MixpanelDD'
import { localStorageGetUserID } from '../../localStorage/localStorageUser'

const MixpanelContext = createContext<MixpanelContextType>({
    trackEvent: () => {},
})

export const useMixpanel = () => useContext(MixpanelContext)

interface MixpanelProviderProps {
    children: ReactNode
}

export function MixpanelProvider({ children }: MixpanelProviderProps) {
    const userId = localStorageGetUserID() as string

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN as string)
            mixpanel.identify(userId)
        }
    })

    function trackEvent<T extends keyof EventMap>(event: T, properties: EventMap[T]) {
        if (process.env.REACT_APP_ENV === 'production') {
            Object.keys(properties).length > 0 ? mixpanel.track(event, properties) : mixpanel.track(event)
        }
    }

    return <MixpanelContext.Provider value={{ trackEvent }}>{children}</MixpanelContext.Provider>
}

