import * as React from 'react'
import {
    Box,
    SwipeableDrawer,
    Button,
    List,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material'
import { HamburgerMenuIcon } from './SwipableSideDrawer.styles'
import { UbcSchedulerLogo } from '../../components/logo/UbcSchedulerLogo1'
import { RateReviewOutlined } from '@mui/icons-material'
import { ubcReportIssueForm } from '../../libraries/googleForms/formLinks'

export const SwipableSideDrawer = () => {
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false)

    const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        // prettier-ignore
        if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return
        }
        setDrawerOpen(open)
    }

    return (
        <>
            <Button style={{ minWidth: '40px' }} onClick={toggleDrawer(true)}>
                <HamburgerMenuIcon />
            </Button>
            <SwipeableDrawer
                anchor={'left'}
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
            >
                <SideDrawerItems toggleDrawer={toggleDrawer} />
            </SwipeableDrawer>
        </>
    )
}

// Items in the Swipable Side Drawer
const itemsInSideDrawer = [
    { icon: null, text: <UbcSchedulerLogo fontSize={25} lightBackground={true} />, onClick: () => {} },
    { icon: <RateReviewOutlined />, text: 'Report an Issue', onClick: () => window.open(ubcReportIssueForm, '_blank') },
]

const SideDrawerItems = ({ toggleDrawer }: { toggleDrawer: (open: boolean) => void }) => {
    return (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
        >
            <List>
                {itemsInSideDrawer.map((item) => {
                    if (item.icon === null) {
                        return <ListItem alignItems={'flex-start'}>{item.text}</ListItem>
                    }
                    return (
                        <ListItem disablePadding onClick={() => item.onClick()}>
                            <ListItemButton>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
            <Divider />
        </Box>
    )
}
