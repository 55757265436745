import { createContext, useEffect } from 'react'
import { red, pink, green, lime, lightBlue, grey, purple, yellow, orange, brown, teal, deepOrange } from '@mui/material/colors'
import { sessionStoreGetScheduleFN } from '../services/sessionStorage'

export const CourseColorContext = createContext()

let availableColors = [
    { colorName: 'pink', color: pink[900], backgroundColor: pink[100] },
    { colorName: 'lime', color: lime[900], backgroundColor: lime[100] },
    { colorName: 'grey', color: grey[900], backgroundColor: grey[100] },
    { colorName: 'deepOrange', color: deepOrange[900], backgroundColor: deepOrange[100] },
    { colorName: 'yellow', color: yellow[900], backgroundColor: yellow[100] },
    { colorName: 'teal', color: teal[900], backgroundColor: teal[100] },
    { colorName: 'brown', color: brown[900], backgroundColor: brown[100] },
    { colorName: 'orange', color: orange[900], backgroundColor: orange[100] },
    { colorName: 'purple', color: purple[900], backgroundColor: purple[100] },
    { colorName: 'green', color: green[900], backgroundColor: green[100] },
    { colorName: 'lightBlue', color: lightBlue[900], backgroundColor: lightBlue[100] },
    { colorName: 'pink2', color: pink[900], backgroundColor: pink[100] },
    { colorName: 'lime2', color: lime[900], backgroundColor: lime[100] },
    { colorName: 'grey2', color: grey[900], backgroundColor: grey[100] },
    { colorName: 'deepOrange2', color: deepOrange[900], backgroundColor: deepOrange[100] },
    { colorName: 'yellow2', color: yellow[900], backgroundColor: yellow[100] },
    { colorName: 'teal2', color: teal[900], backgroundColor: teal[100] },
    { colorName: 'brown2', color: brown[900], backgroundColor: brown[100] },
    { colorName: 'orange2', color: orange[900], backgroundColor: orange[100] },
    { colorName: 'purple2', color: purple[900], backgroundColor: purple[100] },
    { colorName: 'green2', color: green[900], backgroundColor: green[100] },
    { colorName: 'lightBlue2', color: lightBlue[900], backgroundColor: lightBlue[100] },
]

let usedColors = []

export const CourseColorProvider = (props) => {
    useEffect(() => {
        loadColors()
    }, [])

    const addCourseColor = (courseName) => {
        const color = availableColors.pop()
        usedColors.push(color)
        return color
    }

    const removeCourseColor = (courseColor) => {
        const usedColorIndex = usedColors.findIndex((usedColor) => usedColor.colorName === courseColor)
        const usedColor = usedColors.splice(usedColorIndex, 1)[0]
        availableColors.push(usedColor)
    }

    const loadColors = () => {
        const sessionsAndTerms = [
            { session: 'W', term: '1' },
            { session: 'W', term: '2' },
            { session: 'S', term: '1' },
            { session: 'S', term: '2' },
        ]

        sessionsAndTerms.forEach((sessionAndTerm) => {
            const data = sessionStoreGetScheduleFN(sessionAndTerm)
            if (data === null) return
            data.coursesInfo.courses.forEach((course) => {
                const usedColorName = course.courseColors.colorName
                const usedColorIndex = availableColors.findIndex((availableColor) => availableColor.colorName === usedColorName)
                const usedColorObject = availableColors.splice(usedColorIndex, 1)[0]
                usedColors.push(usedColorObject)
            })
        })
    }

    return (
        <CourseColorContext.Provider
            value={{
                loadColors: loadColors,
                addCourseColor: addCourseColor,
                removeCourseColor: removeCourseColor,
            }}
        >
            {props.children}
        </CourseColorContext.Provider>
    )
}
