import { useContext, createContext, ReactNode, FC, createRef, RefObject, useState } from 'react'

// Context Types

// Context
const ScheduleSavedThumbnailRefContext = createContext<RefObject<HTMLSpanElement>>(createRef<HTMLSpanElement>())

// Hooks
export const useScheduleSavedThumbnailRef = () => useContext(ScheduleSavedThumbnailRefContext)

// Providers

interface Props {
    children: ReactNode
}

export const ScheduleThumbnailRefProvider: FC<Props> = ({ children }) => {
    /* saved schedules to be displayed on DI > ScheduleThumbnailRef */
    const scheduleThumbnailRef = createRef<HTMLSpanElement>()

    return (
        <ScheduleSavedThumbnailRefContext.Provider value={scheduleThumbnailRef}>
            {children}
        </ScheduleSavedThumbnailRefContext.Provider>
    )
}
