import { useContext, createContext, Dispatch, SetStateAction, ReactNode, useState, FC, useEffect } from 'react'
import { tInstruction } from '../types/InstructionDD'
import { localStorageCheckIfInstructionIsDone, localStorageSetInstructionIsDone } from '../services/localStorage'

export const instructionSelectCampus: tInstruction = { step: 1, heading: 'Select Campus', gif: '/tutorial_step1.gif' }
export const instructionSelectTerm: tInstruction = { step: 2, heading: 'Select Academic Term', gif: '/tutorial_step1.gif' }
export const instructionAddCourse: tInstruction = { step: 3, heading: 'Search and Add Courses', gif: '/tutorial_step1.gif' }
export const instructionGenerateSchedule: tInstruction = { step: 4, heading: 'Click to Create Schedule', gif: '/tutorial_step2.gif' }
export const instructionCompletedStep: tInstruction = { step: 5, heading: '', gif: '' }

// prettier-ignore
const instructions: tInstruction[] = [
    instructionSelectTerm,
    instructionAddCourse,
    instructionGenerateSchedule,
    instructionCompletedStep,
]

interface tInstructionState {
    currentInstruction: tInstruction
    setCurrentInstruction: Dispatch<SetStateAction<tInstruction>>
    nextInstruction: (instruction: tInstruction) => void
}

// Context
const InstructionContext = createContext<tInstructionState>({
    currentInstruction: instructions[0],
    setCurrentInstruction: () => {},
    nextInstruction: () => {},
})

// Hooks
export const useInstruction = () => useContext(InstructionContext)

// Providers

interface Props {
    children: ReactNode
}

export const InstructionProvider: FC<Props> = ({ children }) => {
    const [currentInstruction, setCurrentInstruction] = useState<tInstruction>(instructions[0])

    useEffect(() => {
        if (localStorageCheckIfInstructionIsDone()) {
            setCurrentInstruction(instructionCompletedStep)
        }
    }, [])

    const nextInstruction = (instruction: tInstruction) => {
        if (instruction.step !== currentInstruction.step) return

        setCurrentInstruction((instruction) => {
            const newInstructionNum = 1 + instructions.findIndex((i) => i.step === instruction.step)
            // Store in local storage so that instructions don't appear again
            if (instructions[newInstructionNum].step === instructionCompletedStep.step) {
                localStorageSetInstructionIsDone()
            }
            return instructions[newInstructionNum]
        })
    }

    return <InstructionContext.Provider value={{ currentInstruction: currentInstruction, setCurrentInstruction: setCurrentInstruction, nextInstruction }}>{children}</InstructionContext.Provider>
}
