import { getTravelInfo } from '../../../services/api/APITravelInfo'
import { TravelInfo } from '../../../types/TravelInfoDD'

export function useGetTravelInfo() {
    async function cleanDataAndParseTravelInfo(
        buildingOrigin: string,
        buildingDestination: string
    ): Promise<TravelInfo> {
        buildingOrigin = trimLongSpaces(buildingOrigin)
        buildingDestination = trimLongSpaces(buildingDestination)

        let data: TravelInfo

        try {
            data = await getTravelInfo(buildingOrigin, buildingDestination)
        } catch {
            throw new Error('failed to retrieve direction from the server')
        }
        return data
    }

    return cleanDataAndParseTravelInfo
}

function trimLongSpaces(str: string) {
    return str.replace(/\s+/g, ' ')
}
