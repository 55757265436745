import { Section } from '../../types/SectionDD'
import { savedScheduleindexPrefix, SavedSchedule } from '../../types/SavedScheduleDD'
import { postSavedCourses } from '../api/APIRecommender'
import { tCoursesInfo } from '../../types/CourseInfoDD'

interface localStorageSetScheduleFnProps {
    title: string
    thumbnail: string
    recommended: any
    selectedRecommended: any
    sections: Section[]
    currentSections: Section[]
    coursesInfo: any
    campus: string
}

// Storage FN
export const localStorageScheduleCreateOneFn = ({
    title,
    thumbnail,
    recommended,
    selectedRecommended,
    sections,
    currentSections,
    coursesInfo,
    campus
}: localStorageSetScheduleFnProps) => {
    const id = crypto.randomUUID()

    // send courses in saved schedule to server
    postSavedCourses(
        (coursesInfo as tCoursesInfo).courses.map((course) => ({
            courseName: course.courseName,
            courseNumber: course.courseNumber,
            courseSession: course.courseSession,
            courseTerm: course.courseTerm,
        }))
    )

    const savedSchedule = JSON.stringify({
        id: id,
        title: title,
        date: new Date(),
        thumbnail: thumbnail,
        schedule: { recommended, selectedRecommended, sections, currentSections, coursesInfo: coursesInfo, campus: campus},
    })
    window.localStorage.setItem(savedScheduleindexPrefix + id, savedSchedule)
}

export const localStorageScheduleDeleteOneFn = (scheduleId: string) => {
    window.localStorage.removeItem(savedScheduleindexPrefix + scheduleId) //savedScheduleindexPrefix + id
}

export const localStorageScheduleGetOneFn = (scheduleId: string) => {
    const savedScheduleJson = window.localStorage.getItem(savedScheduleindexPrefix + scheduleId) as string | null //savedScheduleindexPrefix + id
    if (savedScheduleJson === null) return null
    return JSON.parse(savedScheduleJson) as SavedSchedule
}

export const localStorageScheduleGetAllFn = () => {
    let result: SavedSchedule[] = []
    for (let i = 0; i < window.localStorage.length; i++) {
        const index = localStorage.key(i)
        if (index?.includes(savedScheduleindexPrefix)) {
            const savedSchedule = window.localStorage.getItem(index)
            if (savedSchedule) {
                const savedScheduleParsed = JSON.parse(savedSchedule)
                const schedule = {
                    id: savedScheduleParsed.id,
                    title: savedScheduleParsed.title,
                    thumbnail: savedScheduleParsed.thumbnail,
                    date: savedScheduleParsed.date,
                    schedule: savedScheduleParsed.schedule,
                }
                result.push(schedule)
            }
        }
    }
    return result
}
