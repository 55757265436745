import { NavbarContainer, GapBetweenLogoAndActions, NavActionsContainer, NotifyIcon } from './TopNavigationBar.styles'

import { LoadScheduleButton } from '../loadSchedule'
import { SaveScheduleButton } from '../saveSchedule'
import { SwipableSideDrawer } from '../swipableSideDrawer/SwipableSideDrawer'
import { UbcSchedulerLogo } from '../../components/logo/UbcSchedulerLogo1'
import { ubcReportIssueForm } from '../../libraries/googleForms/formLinks'
import { ButtonWithIcon } from '../../components/button/ButtonWithIcon'
import { AppearingItem, DisappearingItem } from '../../components/mediaQueriesComponents'

const TopNavigationBar = () => {
    return (
        <NavbarContainer>
            <NavActionsContainer gap={'10px'}>
                <AppearingItem widthToAppear="801px">
                    <SwipableSideDrawer />
                </AppearingItem>
                <UbcSchedulerLogo fontSize={27} lightBackground={false} />
                <GapBetweenLogoAndActions />
                <LoadScheduleButton />
                <SaveScheduleButton />
                <DisappearingItem widthToDisappear={'345px'}></DisappearingItem>
            </NavActionsContainer>
            <DisappearingItem widthToDisappear={'802px'}>
                <ButtonWithIcon width={'103px'} onClick={() => window.open(ubcReportIssueForm, '_blank')}>
                    <ButtonWithIcon.Text>Report Issue</ButtonWithIcon.Text>
                </ButtonWithIcon>
            </DisappearingItem>
        </NavbarContainer>
    )
}

export default TopNavigationBar
