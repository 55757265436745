import { getSections } from '../../../../services/api/APIWebCrawler'
import { tCourseInfo, tCourseColors } from '../../../../types/CourseInfoDD'
import { Section } from '../../../../types/SectionDD'
import { useCallback, useContext } from 'react'
import { SectionsContext } from '../../../../context/SectionsContext'
import { CourseColorContext } from '../../../../context/CourseColorContext'
import { useCoursesInfoAddCourse, useCoursesInfo, useCampusInfo } from '../../../../context/CoursesInfoContext'
import { checkCourseLimit, checkCourseIsOfferedThisTerm, checkDuplicateCourse } from './Exceptions'
import { instructionAddCourse, useInstruction } from '../../../../context/InstructionContext'

import { useSnackbar } from 'notistack'
import { checkCourseFull } from '../useUpdateSectionsForSolver/checkCourseFull'
import { SuccessMessageCourseAdded } from '../../../../components/snackbar'
import { useAddCourseDataUpdate } from '../../context'

type AddCourseProps = {
    setClearInputBox: React.Dispatch<React.SetStateAction<number>>
}

export const useAddCourse = ({ setClearInputBox }: AddCourseProps) => {
    const { handleSetAllAddCourseData, handleSetCourseLoading } = useAddCourseDataUpdate()
    const { enqueueSnackbar } = useSnackbar()
    const { setSections } = useContext(SectionsContext)
    const { addCourseColor } = useContext(CourseColorContext)
    const coursesInfo = useCoursesInfo()
    const handleAddCourse = useCoursesInfoAddCourse()
    const { nextInstruction } = useInstruction()
    const campusInfo = useCampusInfo()

    const addCourse = useCallback(
        async (courseOption: any, term: string, session: string) => {
            try {
                // Error Handling
                if (courseOption === null) throw Error('NULL')
                await checkCourseLimit(coursesInfo.courses)
                await checkDuplicateCourse(courseOption, coursesInfo.courses)
                // Set Add Course Loading
                handleSetCourseLoading(true, courseOption.department + ' ' + courseOption.courseNumber)

                // Get Course Sections
                // @returnValue sectionTypes           {"Lectures": sections[], "Lab": sections[] ....}
                // @returnValue restrictedSectionTypes {"Lectures": sections[], "Lab": sections[] ....}
                const {
                    sections: newSections,
                    sectionTypes,
                    restrictedSectionTypes,
                    hasRestrictedSections,
                    allSectionsRestricted,
                    warnings,
                    errors,
                } = await getSections(courseOption.department, courseOption.courseNumber, term, session, campusInfo.name)

                checkCourseIsOfferedThisTerm(newSections)

                const courseName = courseOption.department + ' ' + courseOption.courseNumber

                // Check if course is full
                const isCourseFull = checkCourseFull(sectionTypes)

                // Get Course Colors
                const courseColors: tCourseColors = addCourseColor(courseOption.key)

                // Create New Course
                const newCourse: tCourseInfo = {
                    department: courseOption.department,
                    courseNumber: courseOption.courseNumber,
                    courseDescription: courseOption.courseDescription,
                    credit: courseOption.credit,
                    courseColors: courseColors,
                    courseSections: sectionTypes,
                    courseTerm: term,
                    courseSession: session,
                    courseName: courseName,
                    courseVisible: !(isCourseFull || hasRestrictedSections),
                    courseGenerated: false,
                }

                // Move Onto Next Instruction
                nextInstruction(instructionAddCourse)

                // Add New Course to Courses that will be passed to CourseInfo State
                handleAddCourse(newCourse)

                // Context of all sections for current courses
                setSections((sections: Section[]) => [...sections, ...newSections])
                if (!hasRestrictedSections && !isCourseFull) {
                    enqueueSnackbar(SuccessMessageCourseAdded(newCourse.courseName), { variant: 'success' })
                }

                // Open Dialog
                // restricted               => open restricted dialog
                // full                     => open full dialog
                // restricted && full       => open restricted dialog then full dialog
                handleSetAllAddCourseData({
                    loading: isCourseFull || hasRestrictedSections,
                    full: isCourseFull,
                    restricted: hasRestrictedSections,
                    courseName: courseName,
                    courseColorName: courseColors.colorName,
                    restrictedSectionTypes: restrictedSectionTypes,
                    allSectionsRestricted: allSectionsRestricted,
                    snackbarMessages: { success: [], warning: warnings, error: errors },
                })
            } catch (e: any) {
                if (e.message === 'NULL') return
                enqueueSnackbar(e.message, { variant: 'error' })
                handleSetCourseLoading(false, courseOption.department + ' ' + courseOption.courseNumber)
            }
            // Clear "Search Course" Input Box -> Causes Rerendering of Autocomplete Component
            setClearInputBox((prevState) => (prevState === 1 ? 0 : 1))
        },
        [coursesInfo]
    )

    return addCourse
}
