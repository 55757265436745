import styled from 'styled-components'

export const AppearingItem = styled.div<{ widthToAppear: string }>`
    display: none;
    @media (max-width: ${(props) => props.widthToAppear}) {
        display: inline;
    }
`

export const DisappearingItem = styled.div<{ widthToDisappear: string }>`
    display: inline;
    @media (max-width: ${(props) => props.widthToDisappear}) {
        display: none;
    }
`
