import { Section } from '../../../../types/SectionDD'

export const solveMultiThreaded = (sectionsGroup: Section[][], callback: Function) => {
    let workPromises = []

    //determine threads to open
    let iThreads = Math.floor(navigator.hardwareConcurrency / 2)

    const sortedSectionsGroup = sectionsGroup.sort((group1, group2) => (group1.length < group2.length ? 1 : -1))

    if (sortedSectionsGroup[0].length < iThreads) {
        iThreads = sortedSectionsGroup[0].length
    }

    let startIndex = 0
    const loadPerThread = Math.floor(sortedSectionsGroup[0].length / iThreads)
    let remainder = sortedSectionsGroup[0].length % iThreads
    for (let i = 0; i < iThreads; i++) {
        let addition = 0
        if (remainder > 0) {
            remainder--
            addition = 1
        }

        const endIndex = startIndex + loadPerThread + addition
        const top = sortedSectionsGroup[0].slice(startIndex, endIndex)
        const rest = sortedSectionsGroup.slice(1)
        const subSectionsGroup = [top, ...rest]
        workPromises.push(solveByWorkerThread(subSectionsGroup.sort((group1, group2) => (group1.length > group2.length ? 1 : -1))))
        startIndex = endIndex
    }

    Promise.all(workPromises).then((threadResults) => {
        const hasConflict = threadResults.every((result: any) => result.hasConflict)
        const preferrablyunConflictedResults = hasConflict
            ? threadResults
            : threadResults.filter((result: any) => !result.hasConflict)

        const sectionsSolved = preferrablyunConflictedResults
            .map((result: any) => result.sectionsSolved)
            .flatMap((x) => x)
        callback(sectionsSolved, hasConflict)
    })
}

const solveByWorkerThread = (sectionsGroup: Section[][]) => {
    return new Promise((resolve) => {
        //@ts-ignore
        const solveWorker: Worker = new Worker(new URL('./solve_worker.ts', import.meta.url))
        solveWorker.postMessage(sectionsGroup)
        solveWorker.addEventListener('message', (event) => {
            const { hasConflict, sectionsSolved } = event.data
            solveWorker.terminate()
            resolve({ hasConflict, sectionsSolved })
        })
    })
}
