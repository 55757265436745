import styled from 'styled-components'

export const LogoContainer = styled.a<{ fontSize: number }>`
    height: ${(props) => `${props.fontSize}px`};
    font-size: ${(props) => `${props.fontSize}px`};
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    font-family: 'M PLUS 1p', 'Helvetica', 'Arial', sans-serif;
`

export const LogoUBCText = styled.div<{ lightBackground: boolean }>`
    font-size: 1em;
    font-weight: bold;
    color: ${(props) => (props.lightBackground ? '#185abd' : 'white')};
    line-height: 1em;
`

export const LogoSchedulerText = styled.div<{ lightBackground: boolean }>`
    font-size: 0.77em;
    line-height: 1em;
    color: ${(props) => (props.lightBackground ? '#636465' : '#b5b7ba')};
`
