import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { memo } from 'react'
import { useRemoveCourse } from './hooks/useRemoveCourse/useRemoveCourse'

type RemoveCourseButtonProps = {
    courseName: string
    courseColorName: string
}

export const RemoveCourseButton = memo(({ courseName, courseColorName }: RemoveCourseButtonProps) => {
    const removeCourse = useRemoveCourse()

    return (
        <IconButton onClick={() => removeCourse(courseName, courseColorName)} aria-label="delete">
            <ClearIcon fontSize="small" />
        </IconButton>
    )
})
