import { useContext, useState } from 'react'
import { localStorageScheduleGetOneFn } from '../../../services/localStorage/localStorageSchedule'
import { SectionsContext } from '../../../context/SectionsContext'
import { useCoursesInfoSetState, useCampusInfo, useCampusInfoSetState } from '../../../context/CoursesInfoContext'
// import { localStorageGetCampus, localStorageSetCampus } from '../../../services/localStorage/localStorageCampus'
import { useSessionStoreDeleteAllSchedule } from '../../../services/sessionStorage'

export const useLoadSchedule = () => {
    const { setRecommended, setSelectedRecommended, setSections, setCurrentSections } = useContext(SectionsContext)
    const { setCoursesInfo } = useCoursesInfoSetState()
    const { setCampusInfo } = useCampusInfoSetState()
    const { sessionStoreDeleteAllSchedule } = useSessionStoreDeleteAllSchedule()
    const campusInfo = useCampusInfo()

    const loadSchedule = (scheduleId: string) => {
        const savedSchedule = localStorageScheduleGetOneFn(scheduleId)
        if (savedSchedule === null) return false
        // TODO: handle error
        if (savedSchedule.schedule.campus) {
            if (savedSchedule.schedule.campus !== campusInfo.name) {
                sessionStoreDeleteAllSchedule()
            }
            setCampusInfo({name: savedSchedule.schedule.campus})
        } else {
            setCampusInfo({name: 'V'})
        }
        
        setRecommended(savedSchedule.schedule.recommended)
        setSelectedRecommended(savedSchedule.schedule.selectedRecommended)
        setSections(savedSchedule.schedule.sections)
        setCurrentSections(savedSchedule.schedule.currentSections)
        setCoursesInfo(savedSchedule.schedule.coursesInfo)

    }
    return { loadSchedule }
}
