import { useMixpanel } from '../context'

export function useTrackClickUndoRedo() {
    const { trackEvent } = useMixpanel()

    function trackClickUndoRedo() {
        trackEvent('Click Undo/Redo', {})
    }

    return trackClickUndoRedo
}
