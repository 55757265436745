import { useContext, createContext, Dispatch, SetStateAction, ReactNode, useState, FC, useCallback, useEffect } from 'react'
import { sessionStoreGetScheduleFN } from '../services/sessionStorage'
import { tCoursesInfo, tCourseInfo, tCourseColors } from '../types/CourseInfoDD'
import { Campus } from '../types/CampusDD'

// Context Types
interface CoursesInfoSetStateContextTypes {
    setCoursesInfo: Dispatch<SetStateAction<tCoursesInfo>>
    flushCoursesInfo: (term: string, session: string) => void
    setCoursesAsGenerated: () => void
    checkIfScheduleGenerated: () => boolean
    checkIfUnscheduledCourses: () => boolean
}

interface CoursesColorsContextTypes {
    getCourseColors: (courseName: string) => tCourseColors
}

interface CampusContextTypes {
    setCampusInfo: Dispatch<SetStateAction<Campus>>
}

// Context
const CoursesInfoContext = createContext<tCoursesInfo>({ courses: [], term: '', session: '', totalCredits: 0 })

const CoursesInfoAddCourseContext = createContext<(newCourse: tCourseInfo) => void>(() => {})

const CoursesInfoRemoveCourseContext = createContext<(courseNameDeleted: string) => void>(() => {})

const CoursesInfoSetStateContext = createContext<CoursesInfoSetStateContextTypes>({ setCoursesInfo: () => {}, flushCoursesInfo: () => {}, setCoursesAsGenerated: () => {}, checkIfScheduleGenerated: () => false, checkIfUnscheduledCourses: () => false })

const intialCourseColors: tCourseColors = { backgroundColor: '', color: '', colorName: '' }
const CoursesColorsContext = createContext<CoursesColorsContextTypes>({ getCourseColors: (courseName: string) => intialCourseColors })

const CampusInfoContext = createContext<Campus>({name: 'V'})
const CampusInfoSetStateContext = createContext<CampusContextTypes>({ setCampusInfo: () => {}})

// Hooks
export const useCoursesInfo = () => useContext(CoursesInfoContext)

export const useCoursesInfoAddCourse = () => useContext(CoursesInfoAddCourseContext)

export const useCoursesInfoRemoveCourse = () => useContext(CoursesInfoRemoveCourseContext)

export const useCoursesInfoSetState = () => useContext(CoursesInfoSetStateContext)

export const useCoursesColors = () => useContext(CoursesColorsContext)

export const useCampusInfo = () => useContext(CampusInfoContext)

export const useCampusInfoSetState = () => useContext(CampusInfoSetStateContext)

// Providers

interface Props {
    children: ReactNode
}

export const CoursesInfoProvider: FC<Props> = ({ children }) => {
    const [coursesInfo, setCoursesInfo] = useState<tCoursesInfo>({ courses: [], term: '', session: '', totalCredits: 0 })
    const [campusInfo, setCampusInfo ] = useState<Campus>({name: 'V'})

    const handleAddCourse = useCallback((newCourse: tCourseInfo) => {
        // Add New Course to Courses that will be passed to CourseInfo State
        setCoursesInfo((coursesInfo) => {
            const totalCredits = coursesInfo.totalCredits + newCourse.credit
            const courses = [...coursesInfo.courses, newCourse]
            return { ...coursesInfo, courses, totalCredits }
        })
    }, [])

    //eg. CPSC 110
    const handleRemoveCourse = useCallback((courseNameDeleted: string) => {
        setCoursesInfo((coursesInfo: tCoursesInfo) => {
            let removedCredit = 0

            const courses = coursesInfo.courses.filter((existingCourse: tCourseInfo) => {
                // Get Deleted Courses Credit
                if (existingCourse.courseName === courseNameDeleted) {
                    removedCredit = existingCourse.credit
                }
                return existingCourse.courseName !== courseNameDeleted
            })
            const totalCredits = coursesInfo.totalCredits - removedCredit

            return { ...coursesInfo, totalCredits, courses }
        })
    }, [])

    const getCourseColors = useCallback(
        (courseName: string) => {
            for (let i = 0; i < coursesInfo.courses.length; i++) {
                if (coursesInfo.courses[i].courseName === courseName) {
                    return coursesInfo.courses[i].courseColors
                }
            }
            return intialCourseColors
        },
        [coursesInfo.courses]
    )

    const setCoursesAsGenerated = useCallback(() => {
        setCoursesInfo((coursesInfo: tCoursesInfo) => {
            const courses = coursesInfo.courses.map((existingCourse: tCourseInfo) => {
                return { ...existingCourse, courseGenerated: true }
            })
            return { ...coursesInfo, courses }
        })
    }, [])

    const checkIfScheduleGenerated = useCallback(() => {
        return coursesInfo.courses.some((course: tCourseInfo) => course.courseGenerated === true)
    }, [coursesInfo.courses])

    const checkIfUnscheduledCourses = useCallback(() => {
        return coursesInfo.courses.some((course: tCourseInfo) => course.courseGenerated == false)
    }, [coursesInfo.courses])

    const flushCoursesInfo = useCallback((term: string, session: string) => {
        setCoursesInfo({ courses: [], term: term, session: session, totalCredits: 0 })
    }, [])

    return (
        <CoursesInfoContext.Provider value={coursesInfo}>
            <CoursesInfoAddCourseContext.Provider value={handleAddCourse}>
                <CoursesInfoRemoveCourseContext.Provider value={handleRemoveCourse}>
                    <CoursesInfoSetStateContext.Provider value={{ setCoursesInfo, flushCoursesInfo, setCoursesAsGenerated, checkIfScheduleGenerated, checkIfUnscheduledCourses }}>
                        <CoursesColorsContext.Provider value={{ getCourseColors }}>
                            <CampusInfoContext.Provider value= {campusInfo}>
                                <CampusInfoSetStateContext.Provider value={{setCampusInfo}}>
                                    {children}
                                </CampusInfoSetStateContext.Provider>
                            </CampusInfoContext.Provider>
                        </CoursesColorsContext.Provider>
                    </CoursesInfoSetStateContext.Provider>
                </CoursesInfoRemoveCourseContext.Provider>
            </CoursesInfoAddCourseContext.Provider>
        </CoursesInfoContext.Provider>
    )
}
