import { useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useScheduleSave } from './hooks'
import { enqueueSnackbar } from 'notistack'
import { SuccessMessageScheduleSaved } from '../../components/snackbar'
import { WindowEventNotifyNewSavedSchedules } from '../../services/windowEvents/windowEventSchedule'

interface SaveScheduleDialogProps {
    openDialog: boolean
    handleCloseDialog: () => void
}

export const SaveScheduleDialog = ({ openDialog, handleCloseDialog }: SaveScheduleDialogProps) => {
    const { scheduleSave } = useScheduleSave()
    const [scheduleName, setScheduleName] = useState<string>('')

    const handleClickCancelDialog = () => {
        setScheduleName('')
        handleCloseDialog()
    }

    const handleClickSaveSchedule = () => {
        scheduleSave(scheduleName)
        enqueueSnackbar(SuccessMessageScheduleSaved(scheduleName), { variant: 'success' })
        handleCloseDialog()
        setScheduleName('')
        WindowEventNotifyNewSavedSchedules() // Notify all tabs that a new schedule has been saved
    }

    return (
        <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Save Your Schedule</DialogTitle>
            <DialogContent>
                <DialogContentText>Save and name your schedule for future access.</DialogContentText>
                <br />
                <div style={{ display: 'flex' }}>
                    <Typography variant="h6" color={'error'} sx={{ mr: 1 }}>
                        *
                    </Typography>
                    <TextField
                        style={{ width: '100%' }}
                        value={scheduleName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setScheduleName(event.target.value)
                        }}
                        label="Schedule Name"
                        variant="outlined"
                        placeholder="e.g. Fall 2024"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClickCancelDialog} variant="outlined" color={'primary'}>
                    Cancel
                </Button>
                <Button
                    onClick={handleClickSaveSchedule}
                    disabled={scheduleName === ''}
                    variant="contained"
                    color={'primary'}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
