import { useState } from 'react'
import { BiSave } from 'react-icons/bi'
import { iconSizeAndColorProps } from '../../theme/constants'
import { SaveScheduleDialog } from './SaveScheduleDialog'
import { useTrackOpenSave } from '../../services/analytics/hook'
import { ButtonWithTooltip } from '../../components/button'

export const SaveScheduleButton = () => {
    const trackOpenSave = useTrackOpenSave()
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    const handleCloseDialog = () => {
        setOpenDialog(false)
    }
    const handleClickOpenDialog = () => {
        setOpenDialog(true)
        trackOpenSave()
    }

    return (
        <>
            <ButtonWithTooltip onClick={() => handleClickOpenDialog} tooltipTitle="Save Schedule" width={'30px'}>
                <BiSave {...iconSizeAndColorProps('light')} onClick={handleClickOpenDialog} />
            </ButtonWithTooltip>
            <SaveScheduleDialog openDialog={openDialog} handleCloseDialog={handleCloseDialog} />
        </>
    )
}
