import { useContext } from 'react'
import { useCoursesInfo } from '../../context/CoursesInfoContext'
import { SectionsContext } from '../../context/SectionsContext'

// Hook
export const useSessionStoreSetSchedule = () => {
    const { recommended, selectedRecommended, sections, currentSections } = useContext(SectionsContext)
    const coursesInfo = useCoursesInfo()
    const sessionStoreSetSchedule = () => sessionStoreSetScheduleFn({ recommended, selectedRecommended, sections, currentSections, coursesInfo: coursesInfo })
    return { sessionStoreSetSchedule }
}

interface SessionStoreSetScheduleAfterGenerate {
    recommended: any
    selectedRecommended: any
    sections: any
    currentSections: any
    coursesInfo: any
}

// Storage FN
export const sessionStoreSetScheduleFn = ({ recommended, selectedRecommended, sections, currentSections, coursesInfo }: SessionStoreSetScheduleAfterGenerate) => {
    const data = {
        recommended: recommended,
        selectedRecommended: selectedRecommended,
        sections: sections,
        currentSections: currentSections,
        coursesInfo: coursesInfo,
    }
    const dataString = JSON.stringify(data)
    window.sessionStorage.setItem(coursesInfo.session + coursesInfo.term, dataString)
}
