import { APITravelInfo, TravelInfo } from '../../types/TravelInfoDD'

export async function getTravelInfo(buildingOrigin: string, buildingDestination: string): Promise<TravelInfo> {
    console.log('getting')
    const { building_destination, building_origin, time_in_second, distance_in_meter } = (await fetch(
        `${process.env.REACT_APP_API_URL}api/travel-info?buildingorigin=${encodeURIComponent(
            buildingOrigin
        )}&buildingdestination=${encodeURIComponent(buildingDestination)}`
    ).then((response) => response.json())) as APITravelInfo

    return {
        buildingDestination: building_destination,
        buildingOrigin: building_origin,
        timeInSecond: time_in_second,
        distanceInMeter: distance_in_meter,
    }
}
