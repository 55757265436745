import { DetailedSection, Section } from './SectionDD'

export interface APITravelInfo {
    building_origin: string
    building_destination: string
    distance_in_meter: number
    time_in_second: number
}

export interface TravelInfo {
    buildingDestination: string
    buildingOrigin: string
    distanceInMeter: number
    timeInSecond: number
}

export enum TravelInfoPromptState {
    Empty = 'Empty',
    NeedToFillOrigin = 'NeedToFillOrigin',
    NeedToFillDestination = 'NeedToFillDestination',
    Full = 'Full',
}

export interface TravelInfoUpdatersContextType {
    reset: () => void
    activate: () => void
    setSectionOrigin: (newSectionOrigin: Section | null) => void
    setSectionDestination: (newSectionDestination: Section | null) => void
}

export interface TravelInfoValuesContextType {
    isActive: boolean
    sectionOrigin: Section | null
    detailedSectionOrigin: DetailedSection | null
    sectionDestination: Section | null
    detailedSectionDestination: DetailedSection | null
    travelInfoPromptState: TravelInfoPromptState
    travelInfo: TravelInfo | null
}
