import { useContext, createContext, ReactNode, useState, FC, useCallback } from 'react'
import { tAddCourseData, tAddCourseDataSnackbarMessages } from '../../../types/CourseInfoDD'
import { enqueueSnackbar } from 'notistack'
import { SuccessMessageCourseAdded } from '../../../components/snackbar'

// prettier-ignore
const initialState = { 
    loading: false, 
    full: false, 
    courseName: '', 
    courseColorName: '', 
    restrictedSectionTypes: {}, 
    restricted: false, 
    allSectionsRestricted: false,
    snackbarMessages: { 
        success: [], 
        warning: [], 
        error: [] 
    } 
}

// Types

interface CourseAddProcessingUpdateProps {
    handleSetCourseLoading: (loadingState: boolean, courseName: string) => void
    handleClearAllAddCourseData: () => void
    handleAddSnackbarMessage: (snackbarMessage: string, snackbarMessageType: 'success' | 'warning' | 'error') => void
    handleShowAllAddCourseSnackbarMessages: (additionalSnackbarMessages?: tAddCourseDataSnackbarMessages) => void
    handleSetCourseFullAndNotRestricted: () => void
    handleSetAllAddCourseData: (courseAddedData: tAddCourseData) => void
}

// Context
const CourseAddedProcessingContext = createContext<tAddCourseData>(initialState)

const CourseAddProcessingUpdateContext = createContext<CourseAddProcessingUpdateProps>({
    handleSetCourseLoading: (loadingState: boolean, courseName: string) => null,
    handleClearAllAddCourseData: () => null,
    handleAddSnackbarMessage: (snackbarMessage: string, snackbarMessageType: 'success' | 'warning' | 'error') => null,
    handleShowAllAddCourseSnackbarMessages: () => null,
    handleSetCourseFullAndNotRestricted: () => null,
    handleSetAllAddCourseData: (courseAddedData: tAddCourseData) => null,
})

// Hooks
export const useAddCourseData = () => useContext(CourseAddedProcessingContext)

export const useAddCourseDataUpdate = () => useContext(CourseAddProcessingUpdateContext)

// Providers

interface Props {
    children: ReactNode
}

export const CoursesAddedProcessingProvider: FC<Props> = ({ children }) => {
    const [courseAddedData, setCourseAddedData] = useState<tAddCourseData>(initialState)

    const handleSetCourseLoading = useCallback((loadingState: boolean, courseName: string) => {
        setCourseAddedData((courseAddedData) => ({ ...courseAddedData, courseName: courseName, loading: loadingState }))
    }, [])

    const handleSetAllAddCourseData = useCallback((courseAddedData: tAddCourseData) => {
        setCourseAddedData(courseAddedData)
    }, [])

    const handleClearAllAddCourseData = useCallback(() => {
        setCourseAddedData(initialState)
    }, [])

    const handleAddSnackbarMessage = useCallback((snackbarMessage: string, snackbarMessageType: 'success' | 'warning' | 'error') => {
        setCourseAddedData((courseAddedData) => ({
            ...courseAddedData,
            snackbarMessages: {
                ...courseAddedData.snackbarMessages,
                [snackbarMessageType]: [...courseAddedData.snackbarMessages[snackbarMessageType], snackbarMessage],
            },
        }))
    }, [])

    const handleShowAllAddCourseSnackbarMessages = useCallback(
        (additionalSnackBarMessages = { success: [], warning: [], error: [] } as tAddCourseDataSnackbarMessages) => {
            const { success: newSuccessMessages, warning: newWarningMessages, error: newErrorMessages } = additionalSnackBarMessages
            const { success: existingSuccessMessages, warning: existingWarningMessages, error: existingErrorMessages } = courseAddedData.snackbarMessages

            const successMessages = [...existingSuccessMessages, ...newSuccessMessages]
            const warningMessages = [...existingWarningMessages, ...newWarningMessages]
            const errorMessages = [...existingErrorMessages, ...newErrorMessages]
            // Course Success / Warnings / Errors - Show Snackbar
            successMessages.length > 0 && enqueueSnackbar(successMessages.join('\n'), { variant: 'success' })
            warningMessages.length > 0 && enqueueSnackbar(warningMessages.join('\n'), { variant: 'warning' })
            errorMessages.length > 0 && enqueueSnackbar(errorMessages.join('\n'), { variant: 'error' })
        },
        [courseAddedData.snackbarMessages]
    )

    const handleSetCourseFullAndNotRestricted = useCallback(() => {
        setCourseAddedData((courseAddedData) => ({ ...courseAddedData, full: true, restricted: false }))
    }, [])

    return (
        <CourseAddProcessingUpdateContext.Provider value={{ handleClearAllAddCourseData: handleClearAllAddCourseData, handleAddSnackbarMessage, handleShowAllAddCourseSnackbarMessages, handleSetCourseFullAndNotRestricted, handleSetAllAddCourseData, handleSetCourseLoading }}>
            <CourseAddedProcessingContext.Provider value={courseAddedData}>{children}</CourseAddedProcessingContext.Provider>
        </CourseAddProcessingUpdateContext.Provider>
    )
}
