import styles from './styles.module.css'
import { memo } from 'react'
import ClassInfo from './3. ClassInfo'
import { tCourseInfo } from '../../types/CourseInfoDD'
import { RemoveCourseButton } from './6. RemoveCourseButton'
import { useCampusInfo } from '../../context/CoursesInfoContext'

type CourseInfoProps = {
    course: tCourseInfo
    isFirstCouseRendered: boolean
}

const CourseInfo = memo(({ course, isFirstCouseRendered }: CourseInfoProps) => {
    const campusInfo = useCampusInfo()
    return (
        <>
            <div className={styles.courseInfoContainer} style={{ backgroundColor: course.courseColors.backgroundColor }}>
                <div className={styles.courseInfoTitleContainer}>
                    <div className={styles.courseInfoTitle}>
                        <span>{course.courseName}</span>
                    </div>
                    <RemoveCourseButton courseName={course.courseName} courseColorName={course.courseColors.colorName} />
                </div>
                <div className={styles.courseInfoDescription}>{course.courseDescription}</div>
            </div>
            <div>
                {/* Display Lecture Section First */}
                {course?.courseSections['Lecture']?.length > 0 && <ClassInfo key={'Lecture' + course.courseName} isFirstSectionRendered={isFirstCouseRendered} classType={'Lecture'} course={course} campusInfo={campusInfo} />}
                {/* Display Lab, Tutorial, etc*/}
                {Object.entries(course.courseSections).map(([classType, sections]) => {
                    if (sections.length > 0 && classType !== 'Lecture') {
                        return <ClassInfo key={classType + course.courseName} isFirstSectionRendered={false} classType={classType} course={course} campusInfo={campusInfo}/>
                    }
                    return null
                })}
            </div>
            <div className={styles.courseInfoSpacer} />
        </>
    )
})

export default CourseInfo
