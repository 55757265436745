import { Children, FunctionComponentElement, ReactElement, ReactNode, cloneElement, isValidElement } from 'react'
import { Close } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import { useEffect, useState } from 'react'
import { instructionGenerateSchedule, useInstruction } from '../../context/InstructionContext'
import { tInstruction } from '../../types/InstructionDD'

type TooltipInstruction = {
    instruction: tInstruction
    children: JSX.Element | JSX.Element[]
}

export const TooltipInstruction = ({ instruction, children }: TooltipInstruction) => {
    const { currentInstruction } = useInstruction()
    const [close, setclose] = useState(false) // controlled by close button in tooltip
    const open = currentInstruction.step === instruction.step
    const total = 3 // total number of instruction step

    /** Check for mobile view
     * TODO: Export this into custom Hook so it can be reused
     */
    const [width, setWidth] = useState<number>(window.innerWidth)
    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])
    const isMobile = width <= 868

    const clones = Children.map(children, (child) => {
        return cloneElement(child, {
            className: `${child.props.className} ${open ? 'pulseYellow' : ''}`,
            // onChange: function () {
            //     const originalOnChange = child.props.onChange
            //     if (typeof originalOnChange === 'function') {
            //         originalOnChange.apply(this, arguments)
            //     }
            // },
        })
    })

    const getToolTipPlacement = () => {
        if (isMobile && instructionGenerateSchedule.step === currentInstruction.step) return 'bottom'
        if (isMobile) return 'top'
        return 'right'
    }

    return (
        <Tooltip
            open={open && !close}
            PopperProps={{ style: { zIndex: 10 } }}
            title={
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <div style={{ width: '100%' }}>
                        <h6 style={{ fontSize: 20 }}></h6>
                    </div>
                    <span style={{ lineHeight: 1.25, fontSize: 15, marginTop: 12, color: 'lightblue' }}>
                        {instruction.heading}
                    </span>
                    <div style={{ display: 'flex', fontSize: 7, margin: 5 }}>
                        {Array.from(Array(total), (e, index) => (
                            <CircleIcon
                                key={`progress-icon-${index}`}
                                htmlColor={index + 1 === instruction.step ? 'orange' : 'white'}
                                fontSize="inherit"
                            />
                        ))}
                    </div>
                    <IconButton
                        size={'small'}
                        style={{ position: 'absolute', top: 0, right: 0, color: 'white' }}
                        onClick={() => {
                            setclose(true)
                        }}
                    >
                        <Close fontSize="small"></Close>
                    </IconButton>
                </div>
            }
            arrow
            placement={getToolTipPlacement()}
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: 'common.black',
                        borderRadius: 3,
                        '& .MuiTooltip-arrow': {
                            color: 'common.black',
                        },
                    },
                },
            }}
        >
            {clones[0]}
        </Tooltip>
    )
}
