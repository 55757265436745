import { Button, Typography } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { Section } from '../../types/SectionDD'
import { dontModifySections, selectSpecificRestrictedSectionsForScheduleSolver, useUpdateSectionsForSolver } from './hooks/useUpdateSectionsForSolver/useUpdateSectionsForSolver'
import { useAddCourseData, useAddCourseDataUpdate } from './context'
import styles from './styles.module.css'
import { useRemoveCourse } from './hooks/useRemoveCourse/useRemoveCourse'
// eg. {"CPSC 110 L1J": {selectedForScheduleSolver: true}, "CPSC 110 L1K": {selectedForScheduleSolver: false}....}
export type FormCheckBoxData = {
    [key: string]: { selectedForScheduleSolver: boolean }
}

const ButtonNameAddRestrictedSections = 'Add Restricted Classes'
const ButtonNameRemoveRestrictedSections = "Don't Add Restricted Classes"
const ButtonNameRemoveCourse = 'Remove Course'

export const DialogRestrictedClassesAvailable = () => {
    const { restrictedSectionTypes, restricted, allSectionsRestricted, courseName, courseColorName } = useAddCourseData()
    const { handleClearAllAddCourseData } = useAddCourseDataUpdate()

    const removeCourse = useRemoveCourse()
    const updateSectionsForSolver = useUpdateSectionsForSolver()

    const handleClassesInScheduleSolver = (e: any) => {
        e.preventDefault()

        const submitButtonType = e.nativeEvent.submitter.value

        switch (submitButtonType) {
            case ButtonNameAddRestrictedSections:
                let formCheckboxes = retrieveCheckboxFormData(e)
                // Notice fnHelper used - selectSpecificRestrictedSectionsForScheduleSolver
                updateSectionsForSolver({
                    courseName: courseName,
                    fnCreateNewSection: selectSpecificRestrictedSectionsForScheduleSolver,
                    fnData: formCheckboxes,
                })
                break
            case ButtonNameRemoveRestrictedSections:
                // Notice fnHelper used - dontModifySections
                updateSectionsForSolver({
                    courseName: courseName,
                    fnCreateNewSection: dontModifySections,
                    fnData: {},
                })
                break
            case ButtonNameRemoveCourse:
                removeCourse(courseName, courseColorName)
                handleClearAllAddCourseData() // close dialog
                break
            default:
                return
        }
    }

    if (!restricted) return null

    return (
        <Dialog open={restricted} sx={{ zIndex: 1501 }}>
            <form onSubmit={handleClassesInScheduleSolver}>
                <DialogTitle>Add {courseName} restricted classes?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {allSectionsRestricted && <Typography color="error">*All classes for this course are restricted</Typography>}
                        <Typography> Restricted Classes are reserved for students from specific programs.</Typography>
                        <br />
                        <Typography>Click on each class to see if you meet the restricted criteria.</Typography>
                        <br />
                        <Typography>Checked classes will be added to your schedule.</Typography>
                    </DialogContentText>
                </DialogContent>
                {/* Creates a Scrollable List of Restricted Sections */}

                <div className={styles.dialogRestrictedRowsContainer}>
                    {/* 1. Show Restricted Lecture */}
                    {restrictedSectionTypes !== undefined && restrictedSectionTypes['Lecture'] && restrictedSectionTypes['Lecture'].length > 0 && <RestrictedRows courseActivity={'Lecture'} sections={restrictedSectionTypes['Lecture']} />}
                    {Object.entries(restrictedSectionTypes).map(([courseActivity, sections], index) => {
                        // 2. Show Restricted Other Activity Types eg. Labs, Tutorial, etc.
                        if (courseActivity !== 'Lecture') return <RestrictedRows key={courseActivity + index} courseActivity={courseActivity} sections={sections} />
                        return null
                    })}
                </div>
                <DialogActions>
                    <Button type="submit" value={allSectionsRestricted ? ButtonNameRemoveCourse : ButtonNameRemoveRestrictedSections} variant="contained" color={'error'}>
                        {allSectionsRestricted ? ButtonNameRemoveCourse : ButtonNameRemoveRestrictedSections}
                    </Button>
                    <Button type="submit" value={ButtonNameAddRestrictedSections} variant="contained" color={'success'}>
                        {ButtonNameAddRestrictedSections}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

// Each restricted section has a checkbox for the user to determine if they want to include it in their schedule
const RestrictedRows = ({ courseActivity, sections }: { courseActivity: string; sections: Section[] }) => {
    return (
        <>
            <Typography variant="subtitle1">{courseActivity}</Typography>
            {sections.map((section, index) => {
                return (
                    <div key={courseActivity + section.name + index} className="flex-row">
                        <input type="checkbox" defaultChecked={true} id={courseActivity} name={section.name} />
                        <Typography style={{ paddingLeft: 10 }} variant="body2">
                            {/* TODO: Need API to send Proper Link */}
                            <a target="_blank" href={section.url}>
                                {section.name}
                            </a>
                        </Typography>
                    </div>
                )
            })}
        </>
    )
}

// Create Hashmap of restricted sections and their checkboxes
// {"CPSC 110 L1J": {selectedForScheduleSolver: true}, "CPSC 110 L1K": {selectedForScheduleSolver: false}....}
const retrieveCheckboxFormData = (e: any) => {
    // See if checkbox for section was checked
    let formCheckboxes: FormCheckBoxData = {}
    for (let i = 0; i < e.target.length; i++) {
        if (e.target[i].nodeName === 'INPUT') {
            const name = e.target[i].name
            const checked = e.target[i].checked
            formCheckboxes[name] = { selectedForScheduleSolver: checked }
        }
    }
    return formCheckboxes
}
