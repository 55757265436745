import './App.css'
import Main from './features/Main'

function App() {
    return (
        <div className="App">
            <Main />
        </div>
    )
}

export default App
