import { Typography } from '@mui/material'
import styled from 'styled-components'

export const CustomButtonContainer = styled.button<{
    isVertical: boolean
    width: string
    backgroundColor?: string
    color?: string
    borderColor?: string
    isDisabled: boolean
}>`
    color: ${({ color }) => (color ? color : 'inherit')};
    background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : 'inherit')};
    display: flex;
    flex-direction: ${({ isVertical }) => (isVertical ? 'column' : 'row')};
    justify-content: space-between;
    align-items: center;
    height: 27px;
    border: 1px solid ${({ borderColor }) => (borderColor ? borderColor : 'white')};
    border-radius: 15px;
    padding-inline: 10px;
    width: ${(props) => props.width};

    &:hover {
        cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
    }
`


export const CustomButtonText = styled(Typography).attrs<{ color?: string }>(({ color }) => ({
    variant: 'body2',
    color: color ? color : 'white',
}))``
