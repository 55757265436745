import { useContext } from 'react'
import { Paper } from '@mui/material'
import TopDayCells from '../layout/TopDayCells'
import LeftTimeCells from '../layout/LeftTimeCells'
import MainCells from '../layout/MainCells'
import ConvertToTimeSlot from '../2. converter/ConvertToTimeSlot'
import { SectionsContext } from '../../../context/SectionsContext'
import { useDrop } from 'react-dnd'
import { CalendarNavbar } from '../4. calendarNavbar/CalendarNavbar'

const Calendar = () => {
    const { currentSections, nextSections } = useContext(SectionsContext)
    /** Important: designating Calendard <div> element
     * to be droppable fixes hanging next timeslots bug
     * when user drops outside of nextTimeSlots.
     * so, DO NOT REMOVE THIS HOOK --
     */
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'calendarTimeSlot',
        accept: 'calendarTimeSlot',
        drop: (item) => {
            // cancel any drag event and revert
        },
    }))

    return (
        <Paper className="Paper" elevation={0} sx={{ borderRadius: '20px' }}>
            <CalendarNavbar />
            <div ref={drop} className="grid-calendar-container">
                <ConvertToTimeSlot currentSections={currentSections} nextSections={nextSections} />
                <TopDayCells />
                <LeftTimeCells />
                <MainCells />
            </div>
        </Paper>
    )
}

export default Calendar
