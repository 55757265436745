import { useContext, useEffect } from 'react'
import { useDrop } from 'react-dnd'
import { timeToGridRow } from '../CalendarConstants'
import { SectionsContext } from '../../../context/SectionsContext'
import { UndoRedoContext } from '../../../context/UndoRedoContext'
import { useCoursesColors } from '../../../context/CoursesInfoContext'

const NextTimeSlot = ({ section, timeSlot, isInOverlapGroup }) => {
    const courseName = section.subject + ' ' + section.course
    const { focusedNextSection, focusNextSection, blurNextSection } = useContext(SectionsContext)
    const { currentSections, setCurrentSections } = useContext(SectionsContext)
    const { record } = useContext(UndoRedoContext)
    const { getCourseColors } = useCoursesColors()
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'calendarTimeSlot',
        drop: (item) => {
            // parse from and to sections
            // then invoke handleDrop(from, to)
            const { section: from } = item
            const to = section
            handleDrop(from, to)
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
        hover: () => {
            focusNextSection(section)
        },
    }))

    /**
     * EFFECTS: blur (uncolor) the available next sections when user moves the mouse away
     *          from this next section. As a result, ensure all the next sections get uncolored
     */
    useEffect(() => {
        !isOver && blurNextSection()
    }, [isOver])

    /**
     * TODO: fix the specification V
     * EFFECTS: produce true if the section being hovered by user is
     *          the same section as this section
     * @returns {boolean}
     */
    function isHoverTheSameSection() {
        return section.id === focusedNextSection.id
    }

    /**
     * MODIFIES: currentSections
     * EFFECTS: change the 'from' Section to the 'to' Section in currentSections
     *          (preserves Array order so colors stay with their sections)
     */
    const handleDrop = (from, to) => {
        const replacementIndex = currentSections.findIndex((section) => section.id === from.id)
        let newCurrentSections = currentSections
        record(newCurrentSections)
        newCurrentSections.splice(replacementIndex, 1, to)
        setCurrentSections(newCurrentSections)
    }

    const provideStyle = () => {
        const { color, backgroundColor } = getCourseColors(courseName)
        const gridStyle = {
            gridRow: timeToGridRow(timeSlot.start_time) + ' / ' + timeToGridRow(timeSlot.end_time),
            gridColumn: timeSlot.day,
            borderStyle: isHoverTheSameSection() && 'dashed',
            borderWidth: isHoverTheSameSection() && '2px',
            backgroundColor: isHoverTheSameSection() && backgroundColor,
            transition: '0.25s',
            borderColor: color,
            color: color,
        }
        const overlapGroupStyle = {
            height: timeSlot.end_time - timeSlot.start_time - 4,
        }
        return isInOverlapGroup ? { ...gridStyle, ...overlapGroupStyle } : gridStyle
    }

    return (
        <div className={`outlined-cal-slot cal-slot cal-slot-no-drag-and-drop`} ref={drop} style={provideStyle()}>
            <div className="cal-slot-container">
                <div className="cal-slot-details">
                    <div>{section.subject}</div>
                    <div>{section.course}</div>
                </div>
                <div>{section.section}</div>
                {section.status !== 'Available' && <div className={'cal-slot-status'}>{section.status}</div>}
            </div>
        </div>
    )
}

export default NextTimeSlot
