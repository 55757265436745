import { Stack, Switch } from '@mui/material'
import React from 'react'
import UndoRedo from './UndoRedo'

export const CalendarSettings = () => {
    return (
        <Stack direction="row" spacing={2}>
            <UndoRedo />
        </Stack>
    )
}
