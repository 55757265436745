import useInView from '../calendar/hook/useInView'
import { useCoursesInfoSetState } from '../../context/CoursesInfoContext'
import { useRef, useState } from 'react'
import { DirectionsWalk, Folder, History, Recommend, Save } from '@mui/icons-material'
import { ScheduleRecommendationMobileDropDown } from '../scheduleRecommendationDropDown'
import './dynamicStyle.css'
import { motion } from 'framer-motion'
import { CalendarSettings } from './calendarSettings/CalendarSettings'
import { DynamicIslandFeature } from './DynamicIslandFeature'
import { Badge, Button, Typography } from '@mui/material'
import { useTrackOpenScheduleOptions, useTrackOpenUndoRedo } from '../../services/analytics/hook'
import { useScheduleSavedThumbnailRef } from '../../context/ScheduleSavedContext'
import { AppearingItem } from '../../components/mediaQueriesComponents'
import { useTravelInfoPromptActiveStateManager } from '../travelinfo/hook'
import TravelInfoPromptMobile from '../travelinfo/components/TravelInfoPrompt'

export interface tDynamicIslandFeature {
    contents: {
        name: string
        icon: JSX.Element
        content: JSX.Element
        onFeatureEnter?: Function
        onFeatureExit?: Function
    }[]
}

const DynamicIsland = ({ children: calendar }: { children: JSX.Element }) => {
    const scheduleThumbnailRef = useScheduleSavedThumbnailRef()
    /**
     * DI Modes: controls preview vs specific feature to display
     * preview   content[0]  content[1] ...
     *   -1         0           1   ...
     */
    const [mode, setMode] = useState({ name: 'Preview', index: -1 })

    // /**
    //  * You can add custom hooks and states needed for your feature:
    //  *  - Saved Schedules
    //  **/
    // const { scheduleThumbnailRef, savedSchedules, retrieveSavedSchedule, deleteSavedSchedule, saveSchedule } = useSavedSchedules()
    const trackOpenUndoRedo = useTrackOpenUndoRedo()
    const trackOpenScheduleOptions = useTrackOpenScheduleOptions()

    const { onTravelInfoEnter, onTravelInfoExit } = useTravelInfoPromptActiveStateManager()

    /**
     * You can add your feature here
     * Each feature should have its name and icon and content - component of your feature
     * Additionally, you can provide function that will be executed when you enter/exit feature:
     * onFeatureEnter and onFeatureExit
     */
    const features: tDynamicIslandFeature[] = [
        {
            contents: [
                {
                    name: 'Undo Redo',
                    icon: <History />,
                    content: <CalendarSettings />,
                    onFeatureEnter: trackOpenUndoRedo,
                },
            ],
        },
        {
            contents: [
                {
                    name: 'Schedule Options',
                    icon: <Recommend />,
                    content: <ScheduleRecommendationMobileDropDown />,
                    onFeatureEnter: trackOpenScheduleOptions,
                },
            ],
        },
        // {
        //     contents: [
        //         {
        //             name: 'Travel Estimator',
        //             icon: <DirectionsWalk />,
        //             content: <TravelInfoPromptMobile />,
        //             onFeatureEnter: onTravelInfoEnter,
        //             onFeatureExit: onTravelInfoExit,
        //         },
        //     ],
        // },
    ]

    /**
     * Visisiblity:
     * Dynamic Island is positioned "sticky" to the calendar component,
     * at the bottom of calendar. There two conditions that dictates
     * display of dynamic island:
     *  - only show Dynamic Island when 50% of Calendar is in viewport
     *  - only show Dynamic Island when schedule is generated
     * @param children <Calendar>
     */

    const { inViewRef, inView } = useInView({ threshold: 0.5 })
    const { checkIfScheduleGenerated } = useCoursesInfoSetState()

    // DI Animation
    const childrenRef = useRef<HTMLSpanElement>(null)
    const [initialWidth, setInitialWidth] = useState<number | undefined>(0)
    const [initialHeight, setInitialHeight] = useState<number | undefined>(0)
    const DELAY = 0.3
    function captureInitialDimension() {
        const childContainer = childrenRef.current
        const childContainerRect = childContainer?.getBoundingClientRect()
        setInitialWidth(childContainerRect?.width)
        setInitialHeight(childContainerRect?.height)
    }

    function isPreviewMode() {
        return mode.name === 'Preview'
    }

    function renderFeaturePreview() {
        return features.map((feature) => (
            <Button
                onClick={() => selectFeature(feature)}
                style={{ display: 'flex', flexDirection: 'column', outline: 'none', borderRadius: 25 }}
                color="inherit"
            >
                {feature.contents[0].icon}
                <Typography className="island-nav-button-font" style={{ width: 'max-content' }}>
                    {feature.contents[0].name}
                </Typography>
            </Button>
        ))
    }

    function selectFeature(feature: tDynamicIslandFeature) {
        return setMode({ name: feature.contents[0].name, index: 0 })
    }

    function renderFeature() {
        return features
            .filter((feature) => feature.contents[0].name === mode.name)
            .map((feature) => <DynamicIslandFeature feature={feature} mode={{ mode, setMode }} />)
    }

    return (
        <span ref={inViewRef}>
            {/* Calendar */}
            <span ref={scheduleThumbnailRef}>{calendar}</span>

            {/* Dynamic Island */}
            <AppearingItem widthToAppear="650px">
                {inView && checkIfScheduleGenerated() && (
                    <motion.div
                        className="stick-to-calendar island-panel"
                        key={mode.index}
                        transition={{ delay: DELAY }}
                        initial={{ width: initialWidth, height: initialHeight, opacity: 1 }}
                        animate={{ width: 'max-content', height: 'max-content', opacity: 1 }}
                        onAnimationComplete={captureInitialDimension}
                    >
                        <motion.span
                            ref={childrenRef}
                            className="island-panel-container"
                            key={mode.index}
                            transition={{ delay: DELAY + 0.08 }}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                        >
                            {isPreviewMode() ? renderFeaturePreview() : renderFeature()}
                        </motion.span>
                    </motion.div>
                )}
            </AppearingItem>
        </span>
    )
}

export default DynamicIsland
