import { createRef, useContext } from 'react'
import { localStorageScheduleCreateOneFn } from '../../../services/localStorage/localStorageSchedule'
import { SectionsContext } from '../../../context/SectionsContext'
import { useCampusInfo, useCoursesInfo, useCoursesInfoSetState } from '../../../context/CoursesInfoContext'
import { useScheduleSavedThumbnailRef } from '../../../context/ScheduleSavedContext'
import html2canvas from 'html2canvas'

export const useScheduleSave = () => {
    const { recommended, selectedRecommended, sections, currentSections } = useContext(SectionsContext)
    const coursesInfo = useCoursesInfo()
    const scheduleThumbnailRef = useScheduleSavedThumbnailRef()
    const campus = useCampusInfo().name

    const takeThumbnail = async () => {
        if (scheduleThumbnailRef.current) return (await html2canvas(scheduleThumbnailRef.current)).toDataURL()
    }

    const scheduleSave = async (title: string) => {
        const thumbnail = await takeThumbnail()
        if (thumbnail === undefined) return
        localStorageScheduleCreateOneFn({
            title,
            thumbnail,
            recommended,
            selectedRecommended,
            sections,
            currentSections,
            coursesInfo,
            campus
        })
    }

    return { scheduleSave }
}
