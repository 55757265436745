import React, { useContext } from 'react'
import { SectionsContext } from '../../context/SectionsContext'
import { DialogBase } from './DialogBase'
import dndGIF from './DragDrop.gif'
import { Typography } from '@mui/material'

type GIFProps = {
    srcGIF: string
}

type MessageProps = {
    message: string
}

export const DialogDragDrop = () => {
    const { recommended } = useContext(SectionsContext)
    // DragDropDialog should only open when:
    // - calendar is populated for the first time
    // - disable-dnd-dialog is not in local storage
    const shouldOpenDialog = !('disable-dnd-dialog' in window.localStorage) && recommended.compact.length > 0
    const header = 'Try Drag and Dropping a Timeslot'
    const message = 'See other classes and customize your schedule'

    const persistDisable = () => {
        window.localStorage['disable-dnd-dialog'] = true
    }

    const DragDropDialogContent = () => {
        return (
            <>
                <GIF srcGIF={dndGIF} />
                <Message message={message} />
            </>
        )
    }

    const Message = ({ message }: MessageProps) => {
        return (
            <>
                <Typography align="center" variant="h6">
                    {header}
                </Typography>
                <Typography align="center" variant="body1" style={MessageStyle}>
                    {message}
                </Typography>
            </>
        )
    }

    const GIF = ({ srcGIF }: GIFProps) => {
        return <img style={GIFstyle} src={srcGIF} />
    }

    return (
        <DialogBase shouldOpenDialog={shouldOpenDialog} persistDisable={persistDisable}>
            <DragDropDialogContent />
        </DialogBase>
    )
}

const MessageStyle = {}

const GIFstyle = {
    borderRadius: 10,
    width: '100%',
    marginBottom: '1rem',
}
