import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Checkbox from '@mui/material/Checkbox'
import { Button, FormControlLabel, Typography } from '@mui/material'
import { ReactNode, useContext, useEffect, useState } from 'react'
import { SectionsContext } from '../../context/SectionsContext'

type DialogBaseProps = {
    shouldOpenDialog: boolean
    persistDisable: Function
    customControls?: Function
    children: JSX.Element
}

type CloseButtonProps = {
    fnClose: Function
    variant: string
}

type DoNotShowAgainProps = {
    disable: boolean
    setDisable: Function
}

type Wrapper = {
    children: JSX.Element[]
}

type WrapperSingle = {
    children: JSX.Element
}

export const DialogBase = ({ shouldOpenDialog, persistDisable, customControls, children }: DialogBaseProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [disableDialog, setDisableDialog] = useState(false)
    const { recommended } = useContext(SectionsContext)

    useEffect(() => {
        setTimeout(() => {
            if (shouldOpenDialog && !disableDialog) {
                setIsOpen(true)
            }
        }, 1000)
    }, [recommended])

    function closeDialog() {
        setIsOpen(false)
        if (disableDialog) {
            persistDisable()
        } else {
            setDisableDialog(true)
        }
    }

    if (isOpen) {
        return (
            <Layout>
                <Content>{children}</Content>
                <Controls>
                    <DoNotShowAgain disable={disableDialog} setDisable={setDisableDialog} />
                    {customControls && customControls()}
                    <CloseButton variant="button" fnClose={closeDialog} />
                </Controls>
            </Layout>
        )
    }
    return <></>
}

const Layout = ({ children }: Wrapper) => {
    return <div style={{ position: 'absolute', ...LayoutStyle }}>{children}</div>
}

const Content = ({ children }: WrapperSingle) => {
    return <div style={{ ...ContentStyle }}>{children}</div>
}

const Controls = ({ children }: Wrapper) => {
    return <div style={{ ...ControlsStyle }}>{children}</div>
}

const CloseButton = ({ fnClose, variant }: CloseButtonProps) => {
    return (
        <div style={CloseButtonStyle}>
            {variant === 'icon' && (
                <IconButton onClick={() => fnClose()}>
                    <CloseIcon />
                </IconButton>
            )}
            {variant === 'button' && (
                <Button size={'small'} variant="contained" onClick={() => fnClose()}>
                    Close
                </Button>
            )}
        </div>
    )
}

const DoNotShowAgain = ({ disable, setDisable }: DoNotShowAgainProps) => {
    return <FormControlLabel control={<Checkbox checked={disable} onChange={() => setDisable(!disable)} />} label={<Typography fontSize={'small'}>Do not show me this again</Typography>} />
}

// Styles:

const ContentStyle = {}

const ControlsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '90%',
    marginTop: '2rem',
}

const LayoutStyle = {
    width: 'calc(100px + 50vmin)',
    backgroundColor: 'white',
    padding: '0.1rem 0.8rem',
    border: '5px solid #c4c4c4',
    borderRadius: 10,
    zIndex: 10,
    top: '35%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
}

const CloseButtonStyle = {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
}
