import { useMixpanel } from "../context";

interface SimplifiedCourseOption {
    department: string
    courseNumber: string,
    key: string
}

export function useTrackSearchCourses() {
    const { trackEvent } = useMixpanel()
    function trackSearchCourses(courseOption: SimplifiedCourseOption) {
        trackEvent('Search Courses', {
            courseName: courseOption.key,
            department: courseOption.department,
            courseNumber: courseOption.courseNumber,
        })
    }

    return trackSearchCourses
}
