import { Recommended } from '../../../types/RecommendDD'
import { useMixpanel } from '../context'

interface SimplifiedScheduleOption {
    name: string
    value: Recommended
}

export function useTrackClickChooseScheduleOption() {
    const { trackEvent } = useMixpanel()

    function trackClickChooseScheduleOption(scheduleOptions: SimplifiedScheduleOption[], current: Recommended) {
        trackEvent('Choose Schedule Option', { chosenScheduleOption: getScheduleOption(scheduleOptions, current).name })
    }

    function getScheduleOption(scheduleOptions: SimplifiedScheduleOption[], current: Recommended): SimplifiedScheduleOption {
        return scheduleOptions.find((scheduleOption) => scheduleOption.value === current) as SimplifiedScheduleOption
    }

    return trackClickChooseScheduleOption
}
