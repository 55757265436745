import { useEffect, useState } from 'react'
import { LoadScheduleMenu } from './LoadScheduleMenu'
import { AiOutlineFolderOpen } from 'react-icons/ai'
import { iconSizeAndColorProps } from '../../theme/constants'
import { Badge } from '@mui/material'
import { localStorageScheduleGetAllFn } from '../../services/localStorage/localStorageSchedule'
import {
    WindowEventListenForNewSavedSchedules,
    WindowEventRemoveListenerForSavedSchedules,
} from '../../services/windowEvents/windowEventSchedule'
import { useTrackOpenSavedScedule } from '../../services/analytics/hook'
import { ButtonWithTooltip } from '../../components/button'

export const LoadScheduleButton = () => {
    const trackOpenMySchedules = useTrackOpenSavedScedule()
    const [numberOfSavedSchedules, setNumberOfSavedSchedules] = useState<number>(localStorageScheduleGetAllFn().length)
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
    const openMenu = Boolean(menuAnchorEl)

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget)
        trackOpenMySchedules()
    }
    const handleMenuClose = () => {
        setMenuAnchorEl(null)
    }

    const handleUpdateLocalStorage = () => {
        setTimeout(() => setNumberOfSavedSchedules(localStorageScheduleGetAllFn().length), 500)
    }

    useEffect(() => {
        // Listen for changes to local storage ACROSS ALL TABS
        WindowEventListenForNewSavedSchedules(handleUpdateLocalStorage)
        return () => {
            // Remove the handler when the component unmounts
            WindowEventRemoveListenerForSavedSchedules(() => {})
        }
    }, [])

    const buttonId = 'loadScheduleButton'
    const menuId = 'loadScheduleMenu'

    return (
        <>
            <ButtonWithTooltip onClick={(e: any) => handleMenuClick(e)} tooltipTitle="Load Schedule" width={'30px'}>
                <Badge badgeContent={numberOfSavedSchedules} color={'error'}>
                    <AiOutlineFolderOpen
                        id={buttonId}
                        aria-controls={openMenu ? menuId : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        {...iconSizeAndColorProps('light')}
                    />
                </Badge>
            </ButtonWithTooltip>
            <LoadScheduleMenu
                menuOpen={openMenu}
                menuId={menuId}
                ariaLabelledBy={buttonId}
                menuAnchorEl={menuAnchorEl}
                handleMenuClose={handleMenuClose}
            />
        </>
    )
}
