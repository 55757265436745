import { Typography } from '@mui/material'
import { BiSave } from 'react-icons/bi'
import styled from 'styled-components'
import { iconSizeAndColorProps, iconSizeAndColorPropsCustomButton } from '../../theme/constants'
import { FaBell } from 'react-icons/fa'

export const NotifyIcon = styled(FaBell).attrs(() => iconSizeAndColorPropsCustomButton('light'))``

export const NavbarContainer = styled.div`
    position: sticky;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    background-color: #185abd;
    height: 50px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 2px;
    padding-inline: 20px;
    z-index: 8;
    background-color: #185abd;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 0px 0px 4px 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    @media (max-width: 420px) {
        padding-inline: 5px;
    }
`

export const GapBetweenLogoAndActions = styled.div`
    padding-right: 50px;

    @media (max-width: 430px) {
        padding-right: 50px;
    }
    @media (max-width: 410px) {
        padding-right: 25px;
    }
    @media (max-width: 340px) {
        padding-right: 20px;
    }
    @media (max-width: 320px) {
        padding-right: 0px;
    }
`

export const NavActionsContainer = styled.div<{ gap: string }>`
    justify-content: flex-start;
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${(props) => props.gap};
`

export const SaveButtonIcon = styled(BiSave).attrs(() => iconSizeAndColorProps('light'))``
