import { tDynamicIslandFeature } from './DynamicIsland'
import { Button, Stack, Typography } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'
import { useEffect } from 'react'

interface DynamicIslandFeatureProps {
    feature: tDynamicIslandFeature
    mode: {
        mode: {name: string, index: number},
        setMode: Function
    }
}

export const DynamicIslandFeature = ({ feature, mode }: DynamicIslandFeatureProps) => {
    const contents = feature.contents 
    const lastIndex = contents.length - 1
    const onFeatureExit = feature.contents[mode.mode.index].onFeatureExit
    const onFeatureEnter = feature.contents[mode.mode.index].onFeatureEnter

    useEffect(() => {
      if (onFeatureEnter) onFeatureEnter()
    }, [])
    
    function goToPreview() {
        mode.setMode({ name: "Preview", index: -1})
        if (onFeatureExit) onFeatureExit()
    }

    function goToPreviousContent() {
        mode.setMode({ name: mode.mode.name, index:mode.mode.index - 1 })
    }

    function goToNextContent() {
        mode.setMode({ name: mode.mode.name, index:mode.mode.index + 1 })
    }

    const isFirstContent = () => (mode.mode.index === 0)

    const isLastContent = () => (mode.mode.index === lastIndex)

    return (

        <Stack direction={'column'} spacing={1}>
            <Stack direction={'row'} spacing={6} justifyContent="space-between" alignItems={'center'}>
                {
                    isFirstContent() 
                        ? <BackButton goToPreview={goToPreview} /> 
                        : <PreviousButton previousContentName={contents[mode.mode.index - 1].name} goToPreviousContent={goToPreviousContent} /> 
                } 

                {   !isLastContent() && <NextButton nextContentName={contents[mode.mode.index + 1].name} goToNextContent={goToNextContent} />    }
            </Stack>
            <Stack paddingY={1}>{contents[mode.mode.index].content}</Stack>
        </Stack>
    )
}

const BackButton = ({ goToPreview }: { goToPreview: Function }) => {
    return (
        <Button style={{ outline: 'none' }} color="primary" onClick={() => goToPreview()} startIcon={<ArrowBackIos />}>
            <Typography className="island-nav-button-font">Back</Typography>
        </Button>
    )
}

const PreviousButton = ({ previousContentName, goToPreviousContent }: {previousContentName:string, goToPreviousContent: Function }) => {
    return (
        <Button style={{ outline: 'none' }} color="primary" onClick={() => goToPreviousContent()} startIcon={<ArrowBackIos />}>
            <Typography  style={{ width: 'max-content' }} className="island-nav-button-font">{previousContentName}</Typography>
        </Button>
    )
}

const NextButton = ({ nextContentName, goToNextContent }: { nextContentName:string, goToNextContent: Function }) => {
    return (
        <Button onClick={() => goToNextContent()} endIcon={<ArrowForwardIos />}>
            <Typography  style={{ width: 'max-content' }} className="island-nav-button-font">{nextContentName}</Typography>
        </Button>
    )
}
