import React, { ReactNode } from 'react'
import { CustomButtonContainer, CustomButtonText } from './ButtonWithIcon.styles'

interface ButtonWithIconProps {
    isVertical?: boolean
    isDisabled?: boolean
    width: string
    backgroundColor?: string
    borderColor?: string
    color?: string
    children: ReactNode | ReactNode[]
    onClick: () => void
}

export const ButtonWithIcon = ({
    isVertical = false,
    isDisabled = false,
    width,
    backgroundColor,
    borderColor,
    color,
    children,
    onClick,
}: ButtonWithIconProps) => {
    return (
        <CustomButtonContainer
            isVertical={isVertical}
            width={width}
            backgroundColor={backgroundColor}
            borderColor={isDisabled ? 'grey' : borderColor}
            color={isDisabled ? 'grey' : color}
            onClick={isDisabled ? () => {} : onClick}
            isDisabled={isDisabled}
        >
            {children}
        </CustomButtonContainer>
    )
}


ButtonWithIcon.Text = CustomButtonText
