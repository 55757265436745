import { useMixpanel } from '../context'

export function useTrackOpenScheduleOptions() {
    const { trackEvent } = useMixpanel()
    function trackOpenScheduleOptions() {
        trackEvent('Open Schedule Options', {})
    }

    return trackOpenScheduleOptions
}
