import { FC, useEffect } from 'react'
import { Stack } from '@mui/material'
import TopNavigationBar from './topnavbar/TopNavigationBar'
import Calendar from './calendar/1. calendar/Calendar'
import { CourseColorProvider } from '../context/CourseColorContext'
import { SectionsProvider } from '../context/SectionsContext'
import { UndoRedoProvider } from '../context/UndoRedoContext'
import { CoursesInfoProvider } from '../context/CoursesInfoContext'
import { DialogDragDrop } from '../components/dialog/DialogDragDrop'
import { InstructionProvider } from '../context/InstructionContext'
import LeftPanel from './coursesearch/0. LeftPanel'
import { DragAndDropConfig } from '../libraries/dragAndDrop'
import { SnackbarProvider } from 'notistack'
import DynamicIsland from './dynamicIsland/DynamicIsland'
import { MixpanelProvider } from '../services/analytics/context/MixpanelContext'
import { createUserIfNotExists } from '../utils/user'
import TravelInfoProvider from './travelinfo/context/TravelContext'

const Main: FC = () => {
    useEffect(() => {
        createUserIfNotExists()
    }, [])
    
    return (
        <MixpanelProvider>
            <DragAndDropConfig>
                <SnackbarProvider
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                    autoHideDuration={3500}
                    maxSnack={3}
                >
                    <SectionsProvider>
                        <CourseColorProvider>
                            <UndoRedoProvider>
                                <CoursesInfoProvider>
                                    <InstructionProvider>
                                        <TravelInfoProvider>
                                            <div className="Page">
                                                <TopNavigationBar />
                                                <div className="main-page-flexbox main-page-margin">
                                                    <div className="main-page-left">
                                                        <Stack direction="column" spacing={2}>
                                                            <LeftPanel />
                                                        </Stack>
                                                    </div>
                                                    <div className="main-page-right">
                                                        <Stack
                                                            direction="column"
                                                            spacing={0.5}
                                                            style={{ position: 'relative' }}
                                                        >
                                                            <DynamicIsland>
                                                                <Calendar />
                                                            </DynamicIsland>
                                                            <DialogDragDrop />
                                                        </Stack>
                                                    </div>
                                                </div>
                                            </div>
                                        </TravelInfoProvider>
                                    </InstructionProvider>
                                </CoursesInfoProvider>
                            </UndoRedoProvider>
                        </CourseColorProvider>
                    </SectionsProvider>
                </SnackbarProvider>
            </DragAndDropConfig>
        </MixpanelProvider>
    )
}

export default Main
