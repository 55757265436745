import { tSectionTypes } from '../../../../types/CourseInfoDD'
import { Section } from '../../../../types/SectionDD'

// If the course has "Lecture" sections check if they are full => if they are return true
// If the course DOES NOT have lectures check if all sections of one type (eg. Labs) are full => if there is section type (eg. Lab) that is full  return true
export const checkCourseFull = (sectionTypes: tSectionTypes) => {
    if (sectionTypes['Lecture']) {
        return checkLecturesFull(sectionTypes['Lecture'])
    } else {
        return checkIfOneSectionTypeFull(sectionTypes)
    }
}

const checkLecturesFull = (lectureSections: Section[]) => {
    for (let i = 0; i < lectureSections.length; i++) {
        const section = lectureSections[i]

        if (section.status === 'Available' || section.selectedForScheduleSolver) {
            return false
        }
    }
    return true
}

const checkIfOneSectionTypeFull = (sectionTypes: tSectionTypes) => {
    for (let [sectionType, sections] of Object.entries(sectionTypes)) {
        const openSections = sections.filter((section: Section) => section.status === 'Available' || section.selectedForScheduleSolver)
        // If a section type has 0 open classes => course Full
        if (openSections.length === 0) {
            return true
        }
    }
    // No section types were Full => course NOT Full
    return false
}
