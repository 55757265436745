import { useEffect } from 'react'
import { Box, Paper } from '@mui/material'
import SearchPanel from './1. SearchPanel'
import CourseInfo from './2. CourseInfo'
import { useCoursesInfo, useCoursesInfoSetState, useCampusInfo } from '../../context/CoursesInfoContext'
import { useSessionStoreGetSchedule } from '../../services/sessionStorage'
import { tCourseInfo } from '../../types/CourseInfoDD'
import { CoursesAddedProcessingProvider } from './context'

const LeftPanel = () => {
    const { sessionStoreGetSchedule } = useSessionStoreGetSchedule()
    const { checkIfScheduleGenerated } = useCoursesInfoSetState()
    useEffect(() => {
        // Get Schedule Stored In Session on First Render
        sessionStoreGetSchedule({ term: '1', session: 'W' })
    }, [])

    /** courses that users looked up and want to get schedule */
    // {term: "1", session: "W", courses: [], totalCredits: 0, }
    const coursesInfo = useCoursesInfo()
    const campusInfo = useCampusInfo()

    // Panel NOT Visible if Courses Not Generated
    const coursePanelVisible = checkIfScheduleGenerated()

    return (
        <CoursesAddedProcessingProvider>
            <div style={{ minWidth: '20rem' }}>
                <SearchPanel coursesInfo={coursesInfo} campusInfo={campusInfo} />
                {coursePanelVisible && (
                    <Paper className="Paper" sx={{ borderRadius: '20px', marginTop: 2 }}>
                        <Box p={3}>
                            {coursesInfo.courses.map((course, index) => {
                                if (course.courseGenerated) return <CourseInfo key={course.department + course.courseNumber} isFirstCouseRendered={index === 0} course={course} />
                                return null
                            })}
                        </Box>
                    </Paper>
                )}
            </div>
        </CoursesAddedProcessingProvider>
    )
}
export default LeftPanel
