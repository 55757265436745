import { cleanSections } from './DataCleaner'

export const getSections = async (department: string, courseNumber: string, term: string, session: string, campus: string) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/${session}/sections?subject=${department}&number=${courseNumber}&term=${term}&campus=${campus}`
    )
    const data = await response.json()
    for (let j = 0; j<data['sections'].length; j++) {
        data['sections'] = data['sections'].filter((item: { schedule: string | any[] }) => item.schedule.length>0)
    }
    const dataCleaned = cleanSections(data.sections, term)
    return dataCleaned
}

//getSectionDetail
export const getSection = async (
    department: string,
    courseNumber: string,
    session: string,
    section: string,
    campus: string
) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/${session}/sections/detail?subject=${department}&number=${courseNumber}&section=${section}&campus=${campus}`
    )
    const data = await response.json()
    return data
}
