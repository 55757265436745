// Hook
export const useSessionStoreDeleteAllSchedule = () => {
    const sessionStoreDeleteAllSchedule = () => sessionStoreDeleteScheduleFn()
    return {sessionStoreDeleteAllSchedule}
}

export const sessionStoreDeleteScheduleFn = () => {
    const allTerms = ['W1','W2','S1','S2'];
    allTerms.forEach(term => {
        const data = {
            recommended: {
                compact: [],
                consistent: [],
                scatter: [],
                freeDay: [],
                latestStart: [],
                earliestEnd: [],
            },
            selectedRecommended: [],
            sections: [],
            currentSections: [],
            coursesInfo: {
                courses: [],
                session: term[0],
                term: term[1],
                totalCredits: 0
            },
        }
        const dataString = JSON.stringify(data)
        window.sessionStorage.setItem(term, dataString)
    })

}
