import { WarningMessageCourseSpansTwoSemesters } from '../../components/snackbar'
import { Day, Section, Timeslot } from '../../types/SectionDD'

export function cleanSections(sections: Section[], info: any) {
    const sectionsTobeCleaned = {
        _sections: sections,
        _warnings: [] as string[],
        _errors: [] as string[],
        _sectionTypes: {} as { [key: string]: Section[] },
        _restrictedSectionTypes: {} as { [key: string]: Section[] },
        _hasRestrictedSections: false,
        _allSectionsRestricted: true,

        mergeSeparateSections() {
            let curr = 0
            let next = 1
            while (curr < this._sections.length && next < this._sections.length) {
                let currentSchedule = this._sections[curr].schedule
                let nextSchedule = this._sections[next].schedule
                if (!this._sections[next].name && !this._hasSameSchedules(currentSchedule, nextSchedule)) {
                    currentSchedule.push(...nextSchedule)
                } else {
                    curr = next
                }
                next++
            }
            this._sections = this._sections.filter((s) => s.name)
            return this
        },

        handleYearLongSections() {
            let TwoSemesterCourse = false
            for (let section of this._sections) {
                if (section.term === '1-2') {
                    TwoSemesterCourse = true
                    section.term = info.term
                    section.schedule.forEach((timeslot) => (timeslot.term = info.term))
                }
            }
            if (TwoSemesterCourse) {
                this._warnings.push(WarningMessageCourseSpansTwoSemesters)
            }
            return this
        },

        dropEmptyDaySections() {
            this._sections = this._sections.filter((section) => section.schedule.every((timeslot) => timeslot.day))
            return this
        },

        handleFlexibleLearningSections() {
            this._sections = this._sections.map((section) => {
                if (section.activity === 'Flexible Learning') {
                    return { ...section, mode: 'Flexible Learning', activity: 'Lecture' }
                }
                return section
            })
            return this
        },

        dropEmptyTimeSections() {
            this._sections = this._sections.filter((section) =>
                section.schedule.every((timeslot) => timeslot.start_time && timeslot.end_time)
            )
            return this
        },

        dropWaitListSections() {
            this._sections = this._sections.filter((section) => section.activity !== 'Waiting List')
            return this
        },

        trimDays() {
            this._sections = this._sections.map((section) => {
                const trimmedSchedule = section.schedule.map((timeslot) => {
                    return { ...timeslot, day: timeslot.day.slice(0, 3) as Day }
                })
                return { ...section, schedule: trimmedSchedule }
            })
            return this
        },

        setAvailableSectionsAsSelectedForSchedule() {
            this._sections = this._sections.map((section) => {
                if (section.status === 'Available') {
                    return { ...section, selectedForScheduleSolver: true }
                }
                return { ...section, selectedForScheduleSolver: false }
            })
            return this
        },

        // create sectionsTypes Obj => {"Lectures": sections[], "Lab": sections[] ....}
        // This object will be used for coursesInfo.courses.courseSections = sectionTypes
        createVariableSectionTypes() {
            for (let section of this._sections) {
                if (!this._sectionTypes[section.activity]) {
                    this._sectionTypes[section.activity] = [section]
                } else {
                    this._sectionTypes[section.activity].push(section)
                }
            }
            return this
        },

        createVariableRestrictedSectionTypes() {
            for (let section of this._sections) {
                if (!this._restrictedSectionTypes[section.activity] && section.status === 'Restricted') {
                    this._hasRestrictedSections = true
                    this._restrictedSectionTypes[section.activity] = [section]
                } else if (this._restrictedSectionTypes[section.activity] && section.status === 'Restricted') {
                    this._restrictedSectionTypes[section.activity].push(section)
                } else {
                    this._allSectionsRestricted = false
                }
            }
            return this
        },

        getResult() {
            return {
                sections: this._sections,
                sectionTypes: this._sectionTypes,
                allSectionsRestricted: this._allSectionsRestricted,
                hasRestrictedSections: this._hasRestrictedSections,
                restrictedSectionTypes: this._restrictedSectionTypes,
                errors: this._errors,
                warnings: this._warnings,
            }
        },

        _hasSameSchedules(currentSchedule: Timeslot[], nextSchedule: Timeslot[]) {
            return JSON.stringify(currentSchedule) === JSON.stringify(nextSchedule)
        },
    }

    // prettier-ignore
    return sectionsTobeCleaned
          .handleYearLongSections()
          .mergeSeparateSections()
          .dropEmptyDaySections()
          .dropEmptyTimeSections()
          .dropWaitListSections()
          .handleFlexibleLearningSections()
          .trimDays()
          .setAvailableSectionsAsSelectedForSchedule()
          .createVariableSectionTypes()
          .createVariableRestrictedSectionTypes()
          .getResult();
}
