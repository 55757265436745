import { useContext, useEffect, useRef } from 'react'
import { timeToGridRow } from '../CalendarConstants'
import { useDrag } from 'react-dnd'
import { SectionsContext } from '../../../context/SectionsContext'
import { useCoursesColors } from '../../../context/CoursesInfoContext'
import { useSlotClickHandlerForTravelInfo } from '../../travelinfo/hook/index'
import { useTravelInfoValues } from '../../travelinfo/context'

const CurrentTimeSlot = ({ section, timeSlot, isInOverlapGroup }) => {
    const { showNextSections, hideNextSections, draggedSection } = useContext(SectionsContext)
    const { getCourseColors } = useCoursesColors()
    const courseName = section.subject + ' ' + section.course
    const { color, backgroundColor } = getCourseColors(courseName)

    const handleClickForTravelInfoUpdate = useSlotClickHandlerForTravelInfo()
    const { isActive } = useTravelInfoValues()

    /**
     * EFFECTS: set isDragging to true when drag start
     *          invoke hideNextSections when drag ends
     * NOTE: 'item: section' passes this section to
     *        the call sequence of drop function in
     *        NextTimeSlot.jsx file
     */
    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'calendarTimeSlot',
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        item: (monitor) => {
            return { section, timeSlot, color, backgroundColor }
        }, // this section is the section being moved from
        end: () => {
            hideNextSections()
        },
    }))

    /**
     * EFFECTS: When drag starts, show next sections
     */
    useEffect(() => {
        if (isDragging) {
            showNextSections(section)
        }
    }, [isDragging])

    const provideStyle = () => {
        const gridStyle = {
            gridRow: timeToGridRow(timeSlot.start_time) + ' / ' + timeToGridRow(timeSlot.end_time),
            gridColumn: timeSlot.day,
            color: color,
            backgroundColor: backgroundColor,
            opacity: draggedSection.id === section.id && 0.5,
        }
        const overlapGroupStyle = {
            height: timeSlot.end_time - timeSlot.start_time - 4,
        }
        return isInOverlapGroup ? { ...gridStyle, ...overlapGroupStyle } : gridStyle
    }

    return (
        <div
            ref={drag}
            className="solid-cal-slot cal-slot cal-slot-no-drag-and-drop"
            id={isActive? "pointer-on-hover": ""}
            style={provideStyle()}
            onClick={() => handleClickForTravelInfoUpdate(section)}

        >
            <div className="cal-slot-container">
                <div className="cal-slot-details">
                    <div>{section.subject}</div>
                    <div>{section.course}</div>
                </div>
                <div>{section.section}</div>
                {section.status !== 'Available' && <div className={'cal-slot-status'}>{section.status}</div>}
            </div>
        </div>
    )
}

export default CurrentTimeSlot
