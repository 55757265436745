import { Course } from '../../../../types/SearchWordDD'
import { Section } from '../../../../types/SectionDD'
import {
    ErrorMessageCourseLimitExceeded,
    ErrorMessageCourseAleadySelected,
    ErrorMessageCourseNotOfferedThisTerm,
} from '../../../../components/snackbar'
const COURSE_LIMIT = 8

export const checkCourseLimit = (courses: Course[]) => {
    if (courses.length >= COURSE_LIMIT) {
        // limits to max 8 courses
        throw Error(ErrorMessageCourseLimitExceeded)
    }
}

export const checkDuplicateCourse = async (option: any, courses: Course[]) => {
    if (
        courses.some((course) => course.department === option.department && course.courseNumber === option.courseNumber)
    ) {
        throw Error(ErrorMessageCourseAleadySelected)
    }
}

// If there are no sections in that semester the course isn't offered
export const checkCourseIsOfferedThisTerm = (sections: Section[]) => {
    if (sections.length === 0) {
        throw Error(ErrorMessageCourseNotOfferedThisTerm)
    }
}
