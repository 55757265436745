const scheduleSavedEventName = 'schedule-saved'

// Notify all tabs that a new schedule has been saved
export const WindowEventNotifyNewSavedSchedules = () => {
    window.dispatchEvent(new Event(scheduleSavedEventName))
}

// Listen for new saved schedules across all tabs
export const WindowEventListenForNewSavedSchedules = (callback: () => void) => {
    return window.addEventListener(scheduleSavedEventName, callback)
}

// Removes Listener for new saved schedules across all tabs
export const WindowEventRemoveListenerForSavedSchedules = (callback: () => void) => {
    return window.removeEventListener(scheduleSavedEventName, callback)
}
