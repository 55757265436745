import { tCoursesInfo } from '../../../types/CourseInfoDD'
import { useMixpanel } from '../context'

export function useTrackClickGenerateSchedule() {
    const { trackEvent } = useMixpanel()

    function trackClickGenerateSchedule(coursesInfo: tCoursesInfo) {
        trackEvent('Generate Schedule', {
            term: coursesInfo.term,
            session: coursesInfo.session,
            totalCredits: coursesInfo.totalCredits,
        })

        for (let course of coursesInfo.courses) {
            trackEvent('Generate Schedule Course', {
                course: { department: course.department, courseName: course.courseName, courseNumber: course.courseNumber },
            })
        }
    }

    return trackClickGenerateSchedule
}
