import { LogoContainer, LogoSchedulerText, LogoUBCText } from './UbcSchedulerLogo1.styles'

export const UbcSchedulerLogo = ({ fontSize, lightBackground }: { fontSize: number; lightBackground: boolean }) => {
    return (
        <LogoContainer href="javascript:window.location.reload(true)" fontSize={fontSize}>
            <LogoUBCText lightBackground={lightBackground}>UBC</LogoUBCText>
            <LogoSchedulerText lightBackground={lightBackground}>Scheduler</LogoSchedulerText>
        </LogoContainer>
    )
}
