import { useMixpanel } from '../context'

export function useTrackOpenSave() {
    const { trackEvent } = useMixpanel()

    function trackOpenSave() {
        trackEvent('Open Save', {})
    }

    return trackOpenSave
}
