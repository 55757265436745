import styled, { css } from 'styled-components'
import { LuUndo2, LuRedo2 } from 'react-icons/lu'
import { IoMdWalk, IoMdShareAlt } from 'react-icons/io'
import { ImCheckmark } from 'react-icons/im'
import { BsThreeDots } from 'react-icons/bs'
import { iconSizeAndColorProps, iconSizeAndColorPropsCustomButton } from '../../../theme/constants'
import { MdOutlineRateReview } from 'react-icons/md'
import { Typography } from '@mui/material'
import { BlueColored } from '../../../components/utils/style'

export const UndoButtonIcon = styled(LuUndo2).attrs(() => iconSizeAndColorProps('dark'))``

export const RedoButtonIcon = styled(LuRedo2).attrs(() => iconSizeAndColorProps('dark'))``

export const WalkIcon = styled(IoMdWalk).attrs(() => iconSizeAndColorPropsCustomButton('light'))``

export const CheckIcon = styled(ImCheckmark).attrs(() => iconSizeAndColorPropsCustomButton('light'))``

export const ShareIcon = styled(IoMdShareAlt).attrs(() => iconSizeAndColorPropsCustomButton('light'))``

export const ThreeDotsIcon = styled(BsThreeDots).attrs(() => iconSizeAndColorProps('dark'))``

export const ReportIssueIcon = styled(MdOutlineRateReview).attrs(() => iconSizeAndColorPropsCustomButton('light'))``

export const CustomSelectExteriorContainer = styled.div`
    height: 33px;
    width: 250px;
    display: flex;
    background: #1976d2;
    align-items: center;
    padding-left: 10px;
    padding-right: 2px;
    border-radius: 15px;
`
export const CustomSelectExteriorText = styled(Typography).attrs(() => ({ variant: 'body2', color: 'white' }))``

export const CustomSelectInteriorContainer = styled.select`
    border-radius: 15px;
    font-size: 14px;
    border: white;
    padding: 5px;
    margin-left: 10px;
    background-color: #d9d9d9;
`

export const UndoRedoNavbarContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0px;
`

export const CalendarNavbarContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem 0rem 0rem 2rem;
    align-items: center;
    gap: 10px;
`

export const CalendarFeatureContainer = styled.div<{ customColor: string; isDisabled: boolean }>`
    height: 27px;
    display: flex;
    align-items: center;
    background-color: white;
    color: ${({ customColor }) => customColor};
    border-radius: 20px;
    border: 1px solid ${({ customColor }) => customColor};
    ${({ isDisabled }) => (isDisabled ? css`
        &:hover {
            cursor: not-allowed;
        }

        > * {
            pointer-events: none;
        }
    ` : '')}
`

export const CalendarFeatureInnerContainer = styled(CalendarFeatureContainer)`
    height: 21px;
`
