import { useTravelInfoUpdaters, useTravelInfoValues } from '../context/TravelContext'
import { Section } from '../../../types/SectionDD'
import { enqueueSnackbar } from 'notistack'
import { ErrorMessageDuplicateSection } from '../../../components/snackbar'
import { TravelInfoPromptState } from '../../../types/TravelInfoDD'

export function useSlotClickHandlerForTravelInfo() {
    const { isActive, travelInfoPromptState, sectionOrigin, sectionDestination } = useTravelInfoValues()
    const { setSectionOrigin, setSectionDestination } = useTravelInfoUpdaters()
    function handleSectionClick(section: Section) {
        if (!isActive || travelInfoPromptState === TravelInfoPromptState.Full) {
            return
        }

        if (travelInfoPromptState === TravelInfoPromptState.Empty) {
            return setSectionOrigin(section)
        }

        if (travelInfoPromptState === TravelInfoPromptState.NeedToFillDestination) {
            if (JSON.stringify(sectionOrigin) === JSON.stringify(section)) {
                return enqueueSnackbar(ErrorMessageDuplicateSection, { variant: 'error' })
            }
            return setSectionDestination(section)
        }

        if (travelInfoPromptState === TravelInfoPromptState.NeedToFillOrigin) {
            if (JSON.stringify(sectionDestination) === JSON.stringify(section)) {
                return enqueueSnackbar(ErrorMessageDuplicateSection, { variant: 'error' })
            }
            return setSectionOrigin(section)
        }
    }
    return handleSectionClick
}
