import { localStorageGetUserID } from "../localStorage/localStorageUser"

interface ScheduleCourse {
    courseName: string
    courseNumber: string
    courseSession: string
    courseTerm: string
}

export async function postSavedCourses(courses: ScheduleCourse[]) {
    await fetch(`${process.env.REACT_APP_API_URL}api/saved-courses`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ savedScheduleCourses: courses, userId: localStorageGetUserID() }),
    })
}
