import { User } from '../../types/UserDD'

export function localStorageGetUserID(): string | null {
    let userOrNull = localStorage.getItem('user')

    if (userOrNull) {
        return (JSON.parse(userOrNull) as User).id
    }

    return null
}

export function localStorageSetUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user))
}
