import { Button, Tooltip } from '@mui/material'
import { ReactNode } from 'react'

interface ButtonWithTooltipProps {
    children: ReactNode | ReactNode[]
    onClick: (() => void) | ((e: any) => void)
    tooltipTitle: string
    width: string
    disabled?: boolean
}

export const ButtonWithTooltip = ({
    children,
    onClick,
    tooltipTitle,
    width,
    disabled = false,
}: ButtonWithTooltipProps) => {
    return (
        <Tooltip title={tooltipTitle} arrow>
            <Button color="inherit" onClick={onClick} disabled={disabled} style={{ minWidth: width }}>
                {children}
            </Button>
        </Tooltip>
    )
}
