export const ErrorMessageCourseLimitExceeded =
    'Scheduling more than 8 courses slows down the app. Try removing a few before adding more to ensure a smooth experience'
export const ErrorMessageCourseAleadySelected = 'This course is already selected'
export const ErrorMessageCourseNotOfferedThisTerm = "This course isn't offered in this term "
export const ErrorMessageGenerateScheduleNoCourses = 'Please Add Courses Before Generating Schedule'
export const ErrorMessageDuplicateSection = 'This class is already selected'
export const ErrorMessageSelectTermBeforeAddingCourses = 'Please Select Term Before Adding Courses'
export const ErrorMessageSelectCampusBeforeAddingTerms = 'Please Select Campus Before Selecting Terms'
export const WarningMessageCourseSpansTwoSemesters = 'Course Spans Semesters 1 & 2. Add Manually to Next Semester.'
export const WarningMessageCourseOverlapping =
    'No Possible Schedule With These Courses. Please Drag and Drop Course to see Other Options or Remove Course and Try Again'
export const SuccessMessageCourseAdded = (courseName: string) => courseName + ' Added'
export const SuccessMessageScheduleSaved = (scheduleName: string) => 'Schedule Saved: ' + scheduleName
export const InfoMessageCourseRemoved = (courseName: string) => courseName + ' Removed'
export const InfoMessageCourseSessionDataSaved = (sessionName: string) => 'Course Data Saved: ' + sessionName
export const InfoMessageScheduleDeleted = (scheduleName: string) => 'Schedule Deleted: ' + scheduleName
export const ErrorMessageNoBuilding = (courseName: string) => courseName + ' has no specific location'
