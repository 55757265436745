import { DndProvider, TouchTransition, MouseTransition, Preview } from 'react-dnd-multi-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DragAndDropTimeslot from '../../features/calendar/3. timeslot/DragAndDropTimeslot'
import React, { FC, PropsWithChildren } from 'react'

interface DragAndDropConfigProps {
    children: React.ReactNode
}

export const DragAndDropConfig: FC<PropsWithChildren<DragAndDropConfigProps>> = ({ children }) => {
    const dragAndDropConfig = {
        backends: [
            {
                id: 'html5',
                backend: HTML5Backend,
                transition: MouseTransition,
            },
            {
                id: 'touch',
                backend: TouchBackend,
                options: { enableMouseEvents: true },
                preview: true,
                transition: TouchTransition,
            },
        ],
    }
    return (
        <DndProvider options={dragAndDropConfig}>
            <Preview>{DragAndDropTimeslot}</Preview>
            {children}
        </DndProvider>
    )
}
