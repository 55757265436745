import { IconButton } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import UndoIcon from '@mui/icons-material/Undo'
import RedoIcon from '@mui/icons-material/Redo'
import { UndoRedoContext } from '../../../context/UndoRedoContext'
import { Stack } from '@mui/material'
import { useTrackClickUndoRedo } from '../../../services/analytics/hook'
import { UndoButton, RedoButton } from '../../undoRedo'

const UndoRedo = () => {
    const { undo, redo, canUndo, canRedo } = useContext(UndoRedoContext)
    const trackClickUndoRedo = useTrackClickUndoRedo()

    return (
        <Stack direction={'row'}>
            <UndoButton isMobile={true} />
            <RedoButton isMobile={true} />
        </Stack>
    )
}

export default UndoRedo
