import { useMixpanel } from '../context'

export function useTrackOpenUndoRedo() {
    const { trackEvent } = useMixpanel()
    function trackOpenUndoRedo() {
        trackEvent('Open Undo Redo', {})
    }

    return trackOpenUndoRedo
}
