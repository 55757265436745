import { Button, MenuItem, Select, Typography, useTheme } from '@mui/material'
import { withScheduleRecommendationLogic, ScheduleRecommendationDropDownProps } from './withScheduleRecommendationLogic'
import { CalendarFeatureContainer } from '../calendar/4. calendarNavbar/CalendarNavbar.styles'
import { Recommended } from '../../types/RecommendDD'
import { useCoursesInfoSetState } from '../../context/CoursesInfoContext'

// PAY ATTENTION TO -> with ScheduleRecommendationLogic -> all logic is there
export const ScheduleRecommendationDesktopDropDown = withScheduleRecommendationLogic(
    ({ scheduleOptions, handleSwitchRecommendation, selectedRecommended, isDisabled = false }: ScheduleRecommendationDropDownProps) => {
        const { palette } = useTheme()

        const color = isDisabled ? 'grey' : palette.primary.main

        return (
            <CalendarFeatureContainer style={{ paddingRight: 3, width: 'max-content' }} customColor={color} isDisabled={isDisabled}>
                <Typography style={{ paddingInline: '0.5rem' }} color={color} variant={'body2'}>
                    Recommended Schedules:
                </Typography>
                <Select
                    disabled={isDisabled}
                    sx={{
                        height: 21,
                        borderRadius: '20px',
                        color: palette.primary.main,
                    }}
                    value={selectedRecommended}
                    onChange={(e: any) => handleSwitchRecommendation(e)}
                >
                    {scheduleOptions.map((scheduleOption, key) => {
                        return (
                            <MenuItem key={key} value={scheduleOption.value} disabled={scheduleOption.disable}>
                                {scheduleOption.name}
                                {scheduleOption.disable && ' (None)'}
                            </MenuItem>
                        )
                    })}
                </Select>
            </CalendarFeatureContainer>
        )
    }
)
