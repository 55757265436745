import { useState, useContext, memo, useEffect } from 'react'
import {
    Autocomplete,
    Box,
    debounce,
    Paper,
    TextField,
    MenuItem,
    Chip,
    CircularProgress,
    Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { SectionsContext } from '../../context/SectionsContext'
import { tCoursesInfo, tCourseInfo, tCourseOption } from '../../types/CourseInfoDD'
import { useAddCourse } from './hooks/useAddCourse/useAddCourse'
import { useGenerateSchedule } from './hooks/useGenerateSchedule/useGenerateSchedule'
import { DialogRestrictedClassesAvailable } from './4. DialogClassesRestricted'
import { tAddCourseData } from '../../types/CourseInfoDD'
import { useCoursesInfoSetState, useCampusInfo, useCampusInfoSetState } from '../../context/CoursesInfoContext'
import { DialogClassesFull } from './5. DialogClassesFull'
import { useSessionStoreSetSchedule, useSessionStoreGetSchedule, useSessionStoreDeleteAllSchedule } from '../../services/sessionStorage'
import { useSnackbar } from 'notistack'
import { ErrorMessageSelectCampusBeforeAddingTerms, ErrorMessageSelectTermBeforeAddingCourses, InfoMessageCourseSessionDataSaved } from '../../components/snackbar'
import {
    instructionAddCourse,
    instructionGenerateSchedule,
    instructionSelectCampus,
    instructionSelectTerm,
    useInstruction,
} from '../../context/InstructionContext'
import { TooltipInstruction } from '../../components/tooltip/TooltipInstruction'
import { useRemoveCourse } from './hooks/useRemoveCourse/useRemoveCourse'
import { useAddCourseData } from './context'
import { useTrackClickGenerateSchedule, useTrackSearchCourses } from '../../services/analytics/hook'
import { Campus } from '../../types/CampusDD'
import CourseInfo from './2. CourseInfo'
import { CampusCode, CampusName } from '../../types/CampusNameDD'
type SearchPanelProps = {
    coursesInfo: tCoursesInfo
    campusInfo: Campus
}

const SearchPanel = memo(({ coursesInfo, campusInfo}: SearchPanelProps) => {
    const [generateLoading, setGenerateLoading] = useState(false)

    const [clearInputBox, setClearInputBox] = useState(0)
    const [courseOptions, setCourseOptions] = useState([])
    const generateSchedule = useGenerateSchedule()
    const addCourse = useAddCourse({ setClearInputBox })
    const { enqueueSnackbar } = useSnackbar()
    const coursesWinter = require(`../../services/api/courses${process.env.REACT_APP_ACADEMIC_YEAR_FOR_WINTER}W-${campusInfo.name}.json`)
    const coursesSummer = require(`../../services/api/courses${process.env.REACT_APP_ACADEMIC_YEAR_FOR_SUMMER}S-${campusInfo.name}.json`)
    const [courses, setCourses] = useState([])
    const { checkIfScheduleGenerated, checkIfUnscheduledCourses } = useCoursesInfoSetState()

    const trackClickGenerateSchedule = useTrackClickGenerateSchedule()
    const trackSearchCourses = useTrackSearchCourses()

    useEffect(() => {
        if (coursesInfo.session === 'W') setCourses(coursesWinter)
        if (coursesInfo.session === 'S') setCourses(coursesSummer)
    }, [coursesInfo.session])

    /**
     * parse user's raw input of search word then fetch course description data
     * Note1: course description data includes course code, name, description, credits
     * Note2: course description data are options available for user to choose
     * Note3: course data are different from section data
     * @param searchWord
     */
    let loadCourseOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (coursesInfo.term === '') {
            return enqueueSnackbar(ErrorMessageSelectTermBeforeAddingCourses, { variant: 'error' })
        }
        if (event.nativeEvent.type === 'input') {
            const searchWord = event.target.value.toUpperCase()
            const data = courses.filter((c: any) => c.course.includes(searchWord))
            const options: any = data.map((c: any) => ({
                key: c.course,
                label: `${c.course} - ${c.title}`,
                department: c.department,
                courseNumber: c.code,
                courseDescription: c.title,
                credit: c.terms.length === 1 && c.terms[0] === '1-2' ? c.credits / 2 : c.credits,
                offeredTerms: c.terms,
            }))
            setCourseOptions(options)
        }
    }

    const handleCourseSearchInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 2) {
            return
        }
        debounceLoadCourseOptions(e)
    }

    /**
     * Debounce - delay searching for course options by 500ms
     */
    const debounceLoadCourseOptions = debounce((e) => {
        loadCourseOptions(e)
    }, 300)

    return (
        <>
            <Paper className="Paper" elevation={0} sx={{ borderRadius: '20px' }}>
                <Box p={3}>
                    <CampusDropdown campusInfo={campusInfo} coursesInfo={CourseInfo}/>
                    <Term coursesInfo={coursesInfo} />
                    <TooltipInstruction instruction={instructionAddCourse}>
                        <Autocomplete
                            className="w-100"
                            key={clearInputBox}
                            autoSelect={true}
                            options={courseOptions}
                            filterOptions={(options) =>
                                options.filter(
                                    (option: tCourseOption) =>
                                        option.offeredTerms.includes('1-2') ||
                                        option.offeredTerms.includes(coursesInfo.term)
                                )
                            }
                            sx={{ [`& fieldset`]: { borderRadius: '10px' }, mb: '0.5em' }}
                            renderInput={(params) => (
                                <TextField {...params} label="Search Courses" placeholder="eg. ECON 101" />
                            )}
                            onChange={(_: any, courseOption: any) => {
                                // trackSearchCourses(courseOption) -> don't uncomment -> causes stack overflow
                                addCourse(courseOption, coursesInfo.term, coursesInfo.session)
                            }}
                            onInputChange={(e: any) => handleCourseSearchInputChange(e)}
                        />
                    </TooltipInstruction>
                    {checkIfScheduleGenerated() && checkIfUnscheduledCourses() && (
                        <Typography style={{ textAlign: 'center', fontSize: 11, color: '#A7A7A7' }} color={'tertiary'}>
                            The following courses will be added to the schedule:
                        </Typography>
                    )}
                    <CourseChips coursesInfo={coursesInfo} />
                    <TooltipInstruction instruction={instructionGenerateSchedule}>
                        <LoadingButton
                            disabled={coursesInfo.courses.length === 0}
                            className="w-100"
                            variant="contained"
                            color="primary"
                            loading={generateLoading}
                            onClick={() => {
                                trackClickGenerateSchedule(coursesInfo)
                                generateSchedule(setGenerateLoading)
                            }}
                        >
                            Generate Schedule
                        </LoadingButton>
                    </TooltipInstruction>
                    <br />
                </Box>
            </Paper>
            <DialogRestrictedClassesAvailable />
            <DialogClassesFull />
        </>
    )
})

type CampusProps = {
    campusInfo: any
    coursesInfo: any
}
const CampusDropdown = ({ campusInfo, coursesInfo }: CampusProps) => {
    const oldSession = coursesInfo.session
    const oldTerm = coursesInfo.term
    const { flushCoursesInfo } = useCoursesInfoSetState()
    const { flushAllSections } = useContext(SectionsContext)
    const { nextInstruction } = useInstruction()
    const { enqueueSnackbar } = useSnackbar()
    const { setCampusInfo } = useCampusInfoSetState()
    const { sessionStoreDeleteAllSchedule } = useSessionStoreDeleteAllSchedule()

    // important: we need to change year here manually
    const campusOptions = [
        { name: CampusName.Vancouver, value: CampusCode.Vancouver},
        { name: CampusName.Okanagan, value: CampusCode.Okanagan},
    ]

    const updateCampus = (val: string) => {
        let newCampus = CampusName.Vancouver
        if (val===CampusCode.Okanagan) {
            newCampus = CampusName.Okanagan
        }
        enqueueSnackbar(`setting campus to ${newCampus}`, { variant: 'info' })
        sessionStoreDeleteAllSchedule()
        nextInstruction(instructionSelectCampus)
        setCampusInfo({name: val})
        flushAllSections()
        flushCoursesInfo(oldTerm, oldSession)
    }

    return (
        <TooltipInstruction instruction={instructionSelectCampus}>
            {/* prettier-ignore */}
            <TextField 
                className="w-100" 
                id="campus-choice-field" 
                select label="Campus" 
                value={campusInfo.name} 
                onChange={(e) => updateCampus(e.target.value)} 
                sx={{ [`& fieldset`]: { borderRadius: '10px' }, wstepth: '100%', mb: 2 }}
            >
                {campusOptions.map((campusOptions, key) => {
                    return (
                        <MenuItem key={key} value={campusOptions.value}>
                            {campusOptions.name}
                        </MenuItem>
                    )
                })}
            </TextField>
        </TooltipInstruction>
    )
}

type TermProps = {
    coursesInfo: any
}
const Term = ({ coursesInfo }: TermProps) => {
    const { nextInstruction } = useInstruction()
    const { enqueueSnackbar } = useSnackbar()
    const { flushCoursesInfo } = useCoursesInfoSetState()
    const { sessionStoreGetSchedule } = useSessionStoreGetSchedule()
    const { sessionStoreSetSchedule } = useSessionStoreSetSchedule()
    const { flushAllSections } = useContext(SectionsContext)
    const campusInfo = useCampusInfo()

    // important: we need to change year here manually
    const termOptions = [
        { name: `${process.env.REACT_APP_ACADEMIC_YEAR_FOR_WINTER} Winter 1 - (Sep - Dec)`, value: 'W1' },
        { name: `${process.env.REACT_APP_ACADEMIC_YEAR_FOR_WINTER} Winter 2 - (Jan - Apr)`, value: 'W2' },
        { name: `${process.env.REACT_APP_ACADEMIC_YEAR_FOR_SUMMER} Summer 1 - (May - Jun)`, value: 'S1' },
        { name: `${process.env.REACT_APP_ACADEMIC_YEAR_FOR_SUMMER} Summer 2 - (Jul - Aug)`, value: 'S2' },
    ]

    const setTermAndSession = (val: string) => {
        if (campusInfo.name === CampusCode.Okanagan || campusInfo.name ===CampusCode.Vancouver) {
            
        const oldSession = coursesInfo.session
        const oldTerm = coursesInfo.term
        const newSession = val[0]
        const newTerm = val[1]

        if (newSession === '' || newTerm === '') return
        nextInstruction(instructionSelectTerm)
        // If User has Courses and Term Selected => Notify User that Data is Saved
        if (oldSession && oldTerm && coursesInfo.courses.length > 0) {
            const oldTermOption = termOptions.find((termOption) => termOption.value === oldSession + oldTerm)

            oldTermOption !== undefined &&
                enqueueSnackbar(InfoMessageCourseSessionDataSaved(oldTermOption?.name), { variant: 'info' })
        }

        // Store Schedule in Session Storage
        sessionStoreSetSchedule()

        // Set Schedule in Context for new term / session
        const isScheduleStored = sessionStoreGetSchedule({ session: newSession, term: newTerm })

        // If schedule isn't loaded from Session Storage, flush all courses and sections
        if (!isScheduleStored) {
            flushAllSections()
            flushCoursesInfo(newTerm, newSession)
        }
        } else {
            enqueueSnackbar(ErrorMessageSelectCampusBeforeAddingTerms, { variant: 'error' })
        }
    }

    return (
        <TooltipInstruction instruction={instructionSelectTerm}>
            {/* prettier-ignore */}
            <TextField 
                className="w-100" 
                id="term-choice-field" 
                select label="Term" 
                value={coursesInfo.session + coursesInfo.term} 
                onChange={(e) => setTermAndSession(e.target.value)} 
                sx={{ [`& fieldset`]: { borderRadius: '10px' }, wstepth: '100%', mb: 2 }}
            >
                {termOptions.map((termOption, key) => {
                    return (
                        <MenuItem key={key} value={termOption.value}>
                            {termOption.name}
                        </MenuItem>
                    )
                })}
            </TextField>
        </TooltipInstruction>
    )
}

export const CourseChips = ({ coursesInfo }: { coursesInfo: tCoursesInfo }) => {
    const { checkIfUnscheduledCourses } = useCoursesInfoSetState()
    const addCourseData = useAddCourseData()

    if (!checkIfUnscheduledCourses() && !addCourseData.loading) return null

    return (
        <div style={{ marginBottom: '0.25em' }}>
            {coursesInfo.courses.map((course, index) => {
                return <CourseChip key={index} course={course} />
            })}
            {addCourseData.loading && <CourseChipLoading courseName={addCourseData.courseName} />}
        </div>
    )
}

const CourseChip = ({ course }: { course: tCourseInfo }) => {
    const removeCourse = useRemoveCourse()
    if (!course.courseVisible || course.courseGenerated) return null
    // prettier-ignore
    return <Chip 
            className="m-1" 
            label={course.courseName} 
            style={{ color: course.courseColors.color, backgroundColor: course.courseColors.backgroundColor }} 
            onDelete={() => removeCourse(course.courseName, course.courseColors.colorName)} 
            sx={{ [`& .MuiChip-deleteIcon`]: { color: 'white' } }} 
            />
}

const CourseChipLoading = ({ courseName }: { courseName: string }) => {
    return (
        <Chip
            sx={{ width: '104px' }}
            className="m-1"
            label={
                <div className="flex-row-align-center">
                    <span style={{ paddingRight: 10 }}>{courseName}</span>
                    <CircularProgress size={12} />
                </div>
            }
        />
    )
}

export default SearchPanel
