import { Button } from '@mui/material'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useRemoveCourse } from './hooks/useRemoveCourse/useRemoveCourse'
import { selectAllSectionsForScheduleSolver, useUpdateSectionsForSolver } from './hooks/useUpdateSectionsForSolver/useUpdateSectionsForSolver'
import { useAddCourseData, useAddCourseDataUpdate } from './context'

const ButtonNameRemoveCourse = 'Remove Course'
const ButtonNameAddFullLectures = 'Add Full and Blocked Lectures'

export const DialogClassesFull = () => {
    const { courseColorName, courseName, restricted, full } = useAddCourseData()
    const { handleClearAllAddCourseData } = useAddCourseDataUpdate()

    const removeCourse = useRemoveCourse()
    const updateSectionsForSolver = useUpdateSectionsForSolver()

    const handleClassesInScheduleSolver = (e: any) => {
        e.preventDefault()
        const submitButtonType = e.nativeEvent.submitter.value

        switch (submitButtonType) {
            case ButtonNameAddFullLectures:
                // Notice fnCreateNewSection used - selectAllSectionsForScheduleSolver
                updateSectionsForSolver({
                    courseName: courseName,
                    fnCreateNewSection: selectAllSectionsForScheduleSolver,
                    fnData: {},
                })
                break
            case ButtonNameRemoveCourse:
                removeCourse(courseName, courseColorName)
                handleClearAllAddCourseData() // close dialog
                break
            default:
                return
        }
    }

    // VERY IMPORTANT!!!!!!
    // DIALOG CLASSES FULL ONLY OPENS IF
    // 1. full       == true
    // 2. restricted == false
    if (!full && restricted) return null

    return (
        <Dialog open={full && !restricted} sx={{ zIndex: 1501 }}>
            <form onSubmit={handleClassesInScheduleSolver}>
                <DialogTitle>All Lectures for {courseName} are Full or Blocked</DialogTitle>
                <DialogContent>
                    <DialogContentText>Would you like to add full and blocked classes to your schedule?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button type="submit" value={ButtonNameRemoveCourse} variant="contained" color={'error'}>
                        {ButtonNameRemoveCourse}
                    </Button>
                    <Button type="submit" value={ButtonNameAddFullLectures} variant="contained" color={'success'}>
                        {ButtonNameAddFullLectures}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
